@import "../../../../assests/stylesheets/common/variables";

.fee__modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    .fee__wrap {
        position: absolute;
        max-width: 560px;
        height: auto;
        margin: 5% auto;
        box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
        background: #fff;
        color: #000000;
        display: grid;
        position: relative;
        z-index: 1000;
        border-radius: 16px;
        @media screen and (max-width: 480px) {
            max-width: 340px;
            width: 100%;
        }


        .container-- {
            position: relative;
            max-width: 100%;
            width: 100%;
            .select-btn-- {
                display: flex;
                height: 50px;
                align-items: center;
                justify-content: space-between;
                padding: 0 16px;
                border-radius: 8px;
                cursor: pointer;
                background-color: #ffffff;
                .btn-text-- {
                    font-size: 17px;
                    font-weight: 400px;
                    color: #333;
                }
                .arrow-dwn-- {
                    height: 21px;
                    width: 21px;
                    align-items: center;
                    justify-content: baseline;
                    cursor: pointer;
                }
            }
            
            .list-items-- {
              position: relative;
              background-color: #ffffff;
              box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.07);
              border-radius: 8px;
              margin-top: 15px;
              padding: 16px;
            //   display: none;
            }
            .item-- {
                list-style: none;
                display: flex;
                align-items: center;
                height: 50px;
                cursor: pointer;
                transition: 0.3s;
                padding: 0 15px;
                &:hover {
                    background: #F0F6FF;
                    padding: 0 15px;
                }
                .checkbox-- {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 16px;
                    width: 16px;
                    border-radius: 4px;
                    margin-right: 12px;
                    border: 1.5px solid #c0c0c0;
                    transition: all 0.3s ease-in-out;
                    .check-icon-- {
                        font-size: 11px;
                        transform: scale(0);
                    }
                }
                .item-text-- {
                  font-size: 16px;
                  font-weight: 400;
                  color: #333;
                }
            }

        }



        form {
            display: flex;
            flex-direction: column;
            color: #595959;
            .header__part {
                display: flex;
                .add--line--bottom {
                    border-bottom: 2px solid $light-grey;
                    display: flex;
                    margin-bottom: 10px;
                    h2 {
                        color: $dark;
                        @extend %textVariationTwoone;
                        margin-top: 28px;
                        margin-bottom: 20px;
                        @extend %flex;
                        position: relative;

                        @media screen and (max-width: 500px) {
                            margin-top: 20px;
                            margin-bottom: 16px;
                        }

                        .close__modal {
                            position: absolute;
                            padding-left: 5.7px;
                            right: 8%;
                            color: red;
                            cursor: pointer;
                        }
                    }

                }
            }

            .body-part {
                justify-content: center;
                align-items: center;
                width: 100%;
                padding: 10px 45px 25px 45px;

                .horizontal__row {
                    background: #F7F7F7;
                    border: 1px solid #C4C4C4;
                    border-radius: 8px;
                    padding: 12px 22px 8px 22px;
                    display: flex;
                    margin-bottom: 16px;

                    .rounded__part {
                        background: #D9D9D9;
                        border-radius: 59px;
                        width: 45px;
                        position: relative;
                        height: 45px;

                        .school__icon {
                            position: absolute;
                            top: 28%;
                            right: 31%;
                        }
                    }
                }

                .descriptn__part {
                    display: grid;
                    margin-left: 16px;

                    .top__descriptn {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 24px;
                        color: #595959;
                    }

                    .sub__descriptn {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 15px;
                        color: #595959;
                    }
                }


                .log__btns {
                    display: flex;
                    margin-top: 40px;
                    margin-left: 182px;

                    @media screen and (max-width: 500px) {
                        margin-top: 20px;
                        margin-left: 90px;
                    }

                    .logout-btn {
                        width: auto;
                        display: flex;
                        height: 48px;
                        background-color: #1a71ff;
                        border: none;
                        justify-content: center;
                        border-radius: 8px;
                        padding: 16px;
                        left: 1026px;
                        top: 32px;
                        cursor: pointer;

                        @media screen and (max-width: 500px) {
                            font-size: 14px;
                            font-weight: 500;
                            height: 38px;
                            padding: 10px;
                            top: 12px;
                        }
                    }

                    .export-text {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 16px;
                        color: $white;

                        @media screen and (max-width: 500px) {
                            font-size: 14px;
                            font-weight: 500;
                        }
                    }

                    .stay-btn {
                        visibility: hidden;
                        width: auto;
                        height: 48px;
                        top: 32px;
                        left: 912px;
                        background-color: $white;
                        border: 1px solid #d9d9d9;
                        border-radius: 8px;
                        padding: 16px;
                        margin-right: 16px;
                        color: $grey;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 16px;
                        @extend %flex;
                        cursor: pointer;

                        @media screen and (max-width: 500px) {
                            font-size: 14px;
                            height: 38px;
                            padding: 10px;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}