@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .full_blue_btn {
    @apply text-[#130e0e] sm:h-[48px] h-[38px] rounded-lg bg-[#1a71ff] hover:bg-[#2491f0] w-full;
  }

  .white_modal_btn {
    @apply text-[#595959] rounded-lg sm:text-base text-xs font-medium bg-[#ffffff] hover:bg-[#d9d9d9] px-3.5  py-2 border border-[#d9d9d9];
  }

  .blue_modal_btn {
    @apply text-[#ffffff] rounded-lg sm:text-base text-xs font-medium bg-[#1a71ff] hover:bg-[#2491f0] px-3.5  py-2;
  }

  .disable_btn {
    @apply text-[#ffffff] rounded-lg sm:text-base text-xs font-medium bg-[#595959] px-3.5 py-2 ml-4 cursor-not-allowed;
  }

  .white_small_btn {
    @apply text-[#595959] rounded-lg sm:text-sm text-xs font-medium bg-[#ffffff] hover:bg-[#d9d9d9] sm:px-4 px-3 sm:py-2 py-1 border border-[#d9d9d9];
  }

  .blue_small_btn {
    @apply text-[#ffffff] rounded-lg sm:text-sm text-xs font-medium bg-[#1a71ff] hover:bg-[#2491f0] sm:px-4 px-3 sm:py-2 py-1;
  }

  .white___btn {
    @apply text-[#595959] rounded-lg sm:text-base text-xs font-medium bg-[#ffffff] hover:bg-[#d9d9d9] sm:px-4 px-3 sm:h-[48px] h-[38px] border border-[#d9d9d9];
  }

  .blue___btn {
    @apply text-[#ffffff] rounded-lg sm:text-base text-xs font-medium bg-[#1a71ff] hover:bg-[#2491f0] sm:px-4 px-3 sm:h-[48px] h-[38px];
  }

  .blue__item__btn {
    @apply text-[#ffffff] rounded-lg items-center justify-center flex space-x-2 w-full md:text-base text-xs font-medium bg-[#1a71ff] hover:bg-[#003699] sm:px-4 px-3 sm:py-3 py-2;
  }

  .white__item__btn {
    @apply text-[#595959] rounded-lg items-center justify-center flex space-x-2 w-full sm:text-base text-xs font-medium bg-[#ffffff] hover:bg-[#d9d9d9] sm:px-4 px-3 sm:py-3 py-2 border border-[#d9d9d9];
  }

  .modal_title {
    @apply sm:text-base text-sm font-medium text-[#000000] items-center justify-center flex;
  }

  .text_16_500 {
    @apply text-[#000000] sm:text-base text-sm font-medium;
  }

  .text_16_400 {
    @apply text-[#000000] sm:text-base text-sm font-normal;
  }

  .text_12_400 {
    @apply text-[#000000] sm:text-xs text-[10px] font-normal;
  }

  .text_14_400 {
    @apply text-[#000000] sm:text-sm text-xs font-normal;
  }

  .text_14_500 {
    @apply text-[#000000] sm:text-sm text-xs font-medium;
  }

  .text_22_600 {
    @apply text-[#000000] sm:text-[22px] text-[18px] font-semibold;
  }

  .text_18_600 {
    @apply text-[#000000] sm:text-[18px] text-[15px] font-semibold;
  }

  .text_18_500 {
    @apply text-[#000000] sm:text-[18px] text-[15px] font-medium;
  }

  .laBel {
    @apply block mb-2 sm:text-sm text-xs font-normal text-[#595959] dark:text-[#595959]  
  }

  .half_label {
    @apply inline-flex items-center sm:px-3 px-2 text_14_400  text-[#1a71ff] bg-[#F5F9FF] border border-r-0 rounded-l-md dark:border-[#F5F9FF]
  }

  /* .in_Put {
    @apply bg-[#f7f7f7] border-[none] text-[#000000] placeholder-[#8d8d8d] text-sm rounded-lg  dark:bg-[#f7f7f7] focus:border-[#1a71ff] block w-full px-4 sm:h-[56px] h-[46px] dark:text-[#000000] dark:placeholder-[#8d8d8d] dark:border-[#1a71ff]
  }

  .inPut_error {
    @apply border-2 dark:border-[red] focus:ring-[red] focus:border-[red]
  } */


  .in_Put {
    @apply bg-[#f7f7f7] border-[none] text-[#000000] placeholder-[#8d8d8d] text-sm rounded-lg  dark:bg-[#f7f7f7] focus:border-[#1a71ff] block w-full p-4 dark:text-[#000000] dark:placeholder-[#8d8d8d] dark:border-[#1a71ff]
  }

  .inPut_error {
    @apply border-2 dark:border-[red] outline-none focus:ring-[red] focus:border-[red]
  }
}
