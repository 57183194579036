@import "../../../../assests/stylesheets/common/variables";

.main {
  margin-top: 30px;
  padding: 0 50px;
  @media screen and (max-width: 768px) {
    padding: 0 24px;
    margin-top: 24px;
  }

  .home-top {
    @extend %flex;
    justify-content: space-between;
    padding: 5px 0px;
    margin-bottom: 26px;
    @media screen and (max-width: 768px) {
      padding: 0 2px;
    }

    h3 {
      @extend %textVariationFour;
      @extend %flex;
      margin-top: 10px;
      .home3-sub {
        width: 100%;
        position: relative;
        select {
          background: $light-grey;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: $dark;
          border-radius: 8px;
          padding: 0 15px;
          height: 40px;
          cursor: pointer;
          margin-left: 0.5rem;
          appearance: none;
          border: none;
          width: 110px;
          &:focus {
            outline: none !important;
            border: none;
          }
          option {
            @extend %textVariationTwo;
            padding: 10px 0px;
          }
          @media screen and (max-width: 500px) {
            padding: 0 7px;
            height: 34px;
            width: 90px;
          }
        }
        .date-down {
          position: absolute;
          display: block;
          right: 0;
          left: 5.3rem;
          top: 9.5px;
          pointer-events: none;
          @media screen and (max-width: 500px) {
            left: 4.7rem;
          }
        }
      }
      .home-sub {
        @extend %flex;
        background: $light-grey;
        @extend %textVariationOne;
        color: $dark;
        border-radius: 8px;
        width: 100px;
        height: 40px;
        margin-left: 0.5rem;
        img {
          padding-left: 8px;
        }

        @media screen and (max-width: 320px) {
          margin-left: 0.3rem;
          width: 80px;
        }
      }
    }
    .topbar {
      display: flex;
      justify-content: center;
      align-items: center;
      .top-notify {
        background: $light-grey;
        color: $dark;
        border-radius: 8px;
        display: flex;
        padding: 10px 15px;
        @media screen and (max-width: 500px) {
          padding: 6px 7px;
        }
        .date__time {
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: $dark;
          padding-left: 10px;
          .calinda {
            @media screen and (max-width: 500px) {
              display: none;
            }
          }
        }
      }
    }
  }
  .home-card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.6rem;
    @media screen and (max-width: 1180px) {
      grid-template-columns: repeat(1, 1fr);
    }
    .card {
      @extend %flexx;
      padding: 3.5rem 24px;
      background: $primaryColor;
      border-radius: 16px;
      color: $white;
      height: 232px;
      @media screen and (max-width: 500px) {
        padding: 2rem 24px;
        height: 190px;
      }

      h3 {
        @extend %textVariationFour;
        @extend %flex;
        margin-bottom: 3rem;
        margin-top: -1.5rem;
        color: $white;
        @media screen and (max-width: 500px) {
          margin-top: 0rem;
          margin-bottom: 2rem;
        }
      }
      h2 {
        font-size: 50px;
        line-height: 46px;
        font-style: normal;
        font-weight: 500;
        @extend %flex;
        margin-top: 1rem;
        color: $white;
        @media screen and (max-width: 1380px) {
          font-size: 44px;
          margin-bottom: 1.6rem;
        }
        @media screen and (max-width: 1200px) {
          font-size: 40px;
          margin-bottom: 1.2rem;
        }
        @media screen and (max-width: 500px) {
          font-size: 34px;
          margin-bottom: 0rem;
        }
      }
      .mini-card {
        @extend %flex;
        background: $white;
        color: $dark;
        border-radius: 8px;
        height: 40px;
        width: 72px;
        margin-bottom: 1rem;
        margin-left: 7.5rem;
        @media screen and (max-width: 375px) {
          margin-left: 4.5rem;
        }
        @media screen and (max-width: 360px) {
          margin-left: 3.5rem;
        }
        @media screen and (max-width: 395px) {
          margin-left: 5.2rem;
        }
        .percentage {
          margin-right: 0.3rem;
        }
      }
      .home-customers {
        padding-top: 1.5rem;
        @extend %textVariationOne;
        @media screen and (max-width: 375px) {
          display: grid;
          align-items: center;
          justify-content: center;
          padding-top: 0.5rem;
          margin-left: 2.8rem;
        }
        .c-left {
          padding-right: 60px;
          @media screen and (max-width: 395px) {
            padding-left: 0px;
          }
        }
        .c-right {
          padding-left: 60px;
          @media screen and (max-width: 395px) {
            padding-left: 0px;
          }
        }
      }
    }
    .cards {
      @extend %flexx;
      padding: 3.5rem 24px;
      background: $white;
      border-radius: 16px;
      color: $dark;
      border: 1px solid #efefef;
      height: 232px;
      @media screen and (max-width: 500px) {
        padding: 2rem 24px;
        height: 190px;
      }
      h3 {
        @extend %textVariationFour;
        @extend %flex;
        margin-bottom: 1rem;
        margin-top: -1.5rem;
      }
      .o-cards {
        @extend %flex;
        width: 100%;
        padding-right: 0rem;
        justify-content: space-between;
        .mini-cards {
          @extend %flex;
          width: 100%;
          background: $lightPrimary;
          border-radius: 16px;
          padding: 10px 15px;
          @media screen and (max-width: 500px) {
            padding: 4px 10px;
          }
          .card-right {
            margin-right: 30px;
            margin-top: 10px;
            h5 {
              @extend %textVariationOneone;
            }
            p {
              @extend %textVariationOne;
              color: $lighterGrey;
            }
          }
        }
        span {
          border: 1px solid #d7d7d7;
          height: 60px;
          margin-left: 20px;
        }
        h1 {
          color: $secondaryColor;
          font-size: 56px;
          margin-left: 2rem;
          padding-top: 25px;
          @media screen and (max-width: 480px) {
            margin-left: 0.6rem;
            font-size: 34px;
          }
        }
      }
    }
  }
  .chart {
    margin-top: 3.5rem;
    padding: 0;
    @media screen and (max-width: 768px) {
      margin-top: 24px;
    }
    .chart-total {
      @extend %textVariationFour;
      margin-bottom: 2rem;
    }
  }
}
