@import "../../../assests/stylesheets/common/variables";

.backgd {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: auto;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  .wrapper {
    position: absolute;
    max-width: 500px;
    height: auto;
    margin: 10% auto;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
    background: #fff;
    color: #000;
    justify-content: center;
    align-items: center;
    display: grid;
    position: relative;
    z-index: 1000;
    border-radius: 16px;
    @media screen and (max-width: 480px) {
      max-width: 340px;
      width: 100%;
    }
    form {
      display: flex;
      flex-direction: column;
      color: #141414;
      padding: 16px 32px;
      .header-part {
        .img {
          @extend %flex;
          max-width: 106px;
          height: 72px;
          margin: 32px auto 32px auto;
        }
        h2 {
          color: $secondaryColor;
          margin-top: 32px;
          margin-bottom: 8px;
          font-size: 22px;
          font-weight: 600;
          line-height: 24px;
          @extend %flex;
        }
        .h2 {
          color: $dark;
          margin-top: 20px;
          margin-bottom: 8px;
          font-size: 22px;
          font-weight: 600;
          line-height: 24px;
          @extend %flex;
        }

        h4 {
          @extend %flex;
          @extend %textVariationOne;
          color: $grey;
          max-width: 80%;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 32px;
          text-align: center;
        }
        h6 {
          @extend %flex;
          @extend %textVariationzero;
          color: $grey;
          max-width: 70%;
          margin-left: auto;
          margin-right: auto;
          text-align: center;
        }
      }
      .body-part {
        width: 100%;
        padding: 10px 20px 50px 20px;
        p {
          font-size: 14px;
          padding-bottom: 5px;
          color: red;
          font-weight: 400;  
        }
        label {
          color: $grey;
          @extend %textVariationOne;
          margin-bottom: 8px;
        }
        input {
          color: $grey;
          width: 100%;
          @extend %inputOne;
          margin-bottom: 15px;
          margin-top: 8px;
        }
        h3 {
          @extend %textVariationTwo;
          color: $grey;
          margin: 8px 0; 
        }
        h4 {
          @extend %textVariationzero;
          color: $grey;
          margin: 8px 0;
          text-align: center; 
        }
        img {
          position: absolute;
          top: 5.5%;
          right: 5%;
        }
        p {
          text-align: center;
          color: $grey;
          span {
            color: $grey;
            padding: 0 0 0 5px;
          }
        }
        .para {
          text-align: center;
          color: $grey;
          .resend {
            color: $primaryColor;
            margin-left: 5px;
            cursor: pointer;
          }
        }
        button {
          @extend %inputTwo;
          width: 100%;
          @extend %textVariationbtn;
          font-weight: 400;
          margin-top: 8px;
          .links {
            color: $white;
          }
        }
      }
      section {
        font-size: 14px;
        padding-bottom: 8px;
        color: red;
        font-weight: 400;
      }
   
      label {
        color: $grey;
        @extend %textVariationOne;
        margin-bottom: 8px;
      }
      .passwordInput {
        @extend %inputOne;
        color: $grey;
        margin-bottom: 16px;
      }
      .invalid {
        border: 1.5px solid red;
      }
      .invalid:focus {
        border: 1.5px solid red;
      }

      .input {
        width: 100%;
        position: relative;
        .loginInput {
          @extend %inputOne;
          margin-bottom: 16px;
          width: 100%;
        }
        .invalid {
          border: 1.5px solid red;
        }
        .invalid:focus {
          border: 1.5px solid red;
        }
        .input-eye {
          position: absolute;
          display: block;
          right: 16px;
          top: 25px;
          cursor: pointer;
          font-size: 20px;
          color: $grey;
        }
      }
      button {
        @extend %inputTwo;
        @extend %textVariationbtn;
        margin-top: 24px;
        margin-bottom: 16px;
        cursor: pointer;
        .sign {
          color: $white;
        }
      }
    }
  }
}

