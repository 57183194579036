@import "../../../../assests/stylesheets/common/variables";

.home3-main {
  margin-top: 60px;
  padding: 0 50px;
  @media screen and (max-width: 768px) {
    padding: 0 24px;
    margin-top: 24px;
  }
  .home3-bar {
    @extend %flex;
    justify-content: space-between;
    padding: 5px 0px;
    @media screen and (max-width: 768px) {
      padding: 0 2px;
    }
    h3 {
      @extend %textVariationFour;
      @extend %flex;
    }
    .topbar {
      .top-notify {
        @extend %flex;
        justify-content: space-between;
        padding: 5px 0px;
        .mini-card {
          @extend %flex;
          background: $primaryColor;
          color: $white;
          border-radius: 8px;
          width: 100px;
          height: 40px;
          cursor: pointer;
          &:hover {
            background: #2491f0;
          }
          @media screen and (max-width: 500px) {
            width: 85px;
            height: 34px;
          }
          .see-all {
            color: $white;
            margin-right: 0.5rem;
          }
          .percentage {
            color: $white;
          }
        }
        .filter__card {
          margin-right: 10px;
          @extend %flex;
          background: $white;
          border: 1px solid #d9d9d9;
          border-radius: 8px;
          padding: 0 16px;
          height: 40px;
          cursor: pointer;
          &:hover {
            background: #d9d9d9;
          }
          @media screen and (max-width: 500px) {
            padding: 0 10px;
            width: 88px;
          }
          .check__text {
            color: $grey;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            @media screen and (max-width: 500px) {
              font-size: 12px;
          }
          }
        }
      }
    }
  }

  .transactionTable {
    margin-bottom: 30px;
  }
}
