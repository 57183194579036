@import "../../assests/stylesheets/common/variables";

.fees {
  margin-top: 25px;
  @media screen and (max-width: 768px) {
    padding: 0 24px;
    margin-top: 24px;
  }

  .body-part {
    margin-top: 25px;
    padding: 0 50px;
    @media screen and (max-width: 768px) {
      padding: 0 24px;
      margin-top: 24px;
    }
  }

  section {
    width: 100%;
    border-bottom: 1.5px solid $light-grey;
    padding-bottom: 25px;
    @media screen and (max-width: 1200px) {
      overflow-x: scroll;
      white-space: nowrap;
      overflow: auto;
    }
    .personal-info {
      margin-left: 50px;
      @media screen and (max-width: 500px) {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 20px;
      }
    }
    .activ {
      background: #efefef;
      border-radius: 8px;
      padding: 12px;
      margin-right: 40px;
      @media screen and (max-width: 500px) {
        background: none;
        border-radius: none;
        margin-left: -12px;
        margin-bottom: 20px;
        color: #b4a8a8;
      }
    }
    span {
      color: #2d2d2d;
      font-size: 16px;
      line-height: 16px;
      font-weight: 600;
      padding-right: 55px;
      display: inline-block;
      @media screen and (max-width: 500px) {
        padding-right: 30px;
      }
    }
  }

  #sect::-webkit-scrollbar {
    width: thin;
  }

  .intro-txt {
    display: grid;
    padding-left: 50px;
    margin-bottom: 32px;
    @media screen and (max-width: 500px) {
      padding-left: 0;
    }
    h2 {
      color: $dark;
      @extend %textVariationFour;
      margin-top: 35px;
    }
    h4 {
      color: $grey-2;
      @extend %textVariationTwo;
      margin-top: -5px;
    }
  }

  .line-border {
    border: 1px solid #d7d7d7;
    border-radius: 4px;
    height: auto;
    padding: 40px 32px;
    margin: 0 50px 20px 50px;
    @media screen and (max-width: 500px) {
      height: auto;
      margin: 0 0 10px 0;
    }
    .row {
      display: flex;
      margin: 0 -15px -15px -15px;
      flex-wrap: wrap;
      align-items: center;
      .column {
        margin-bottom: 15px;
        padding-right: 15px;
        padding-left: 15px;
        flex: 1;
        max-width: 50%;
        flex-basis: 50%;
        @media screen and (max-width: 1300px) {
          max-width: 100%;
          flex-basis: 100%;
          display: flex;
          justify-content: center;
        }
        form {
          height: auto;
          max-width: 552px;
          display: grid;
          color: $secondaryColor;
          border: none;
          z-index: 1;
          background: transparent;
          @media screen and (max-width: 1300px) {
            width: 100%;
            max-width: 100%;
          }
          .pwd-txt {
            color: $dark;
            font-size: 20px;
            line-height: 24px;
            font-style: normal;
            font-weight: 600;
            margin-bottom: -20px;
            @media screen and (max-width: 500px) {
              font-size: 16px;
              line-height: 24px;
            }
          }
          label {
            color: $grey;
            @extend %textVariationOne;
            margin-bottom: 8px;
          }
          .bankInput {
            width: 100%;
            @extend %inputOne;
          }
          .invalid {
            border: 1.5px solid red;
          }
          .invalid:focus {
            border: 1.5px solid red;
          }
          input {
            background: #f7f7f7;
            border-radius: 8px;
            border: none;
            padding: 0 16px;
            height: 56px;
          }
          .input {
            width: 100%;
            position: relative;
            .loginInput {
              width: 100%;

              background: #f7f7f7;
              border-radius: 8px;
              border: none;
              padding: 0 16px;
              height: 56px;
            }
            .invalid {
              border: 1.5px solid red;
            }
            .invalid:focus {
              border: 1.5px solid red;
            }
            .input-eye {
              position: absolute;
              display: block;
              right: 16px;

              top: 17px;
              cursor: pointer;
              font-size: 20px;
              color: $grey;
            }
          }

          .btn__edit {
            @extend %inputEdit;
            @extend %textVariationTwotwo;
            border: 1px solid #d9d9d9;
            margin-top: 1.5rem;
            margin-bottom: 24px;
            @media screen and (max-width: 500px) {
              max-width: 100%;
            }
            span {
              padding-right: 10px;
            }

            .acc--set {
              margin-top: 20px;
            }
          }

          .btn--pwd {
            @extend %inputTwo;
            @extend %textVariationbtn;
            margin-bottom: 16px;
            cursor: pointer;
            .sign {
              color: $white;
            }
          }
        }
      }
    }
  }
}