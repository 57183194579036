@import "../../assests/stylesheets/common/variables";

#table-container {
  @media screen and (max-width: 1500px) {
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .transaction-tabs {
    margin-top: 52px;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 16px;
    min-width: 1200px;
    width: 100%;
    @media screen and (max-width: 1500px) {
      min-width: 1300px;
    }
    @media screen and (max-width: 500px) {
      font-size: 14px;
      margin: 30px 0;
    }
    td {
      font-weight: 500;
      color: $dark;
      @media screen and (max-width: 500px) {
        font-size: 14px;
      }
      .bank-details {
        display: grid;
        .bank-No {
          color: $dark-11;
          @extend %textVariationOne;
        }
      }
    }
    .bolder__one {
      font-weight: 700;
      color: $dark;
      @media screen and (max-width: 500px) {
        font-size: 14px;
      }
    }
    .bolder__two {
      font-weight: 700;
      color: red;
      @media screen and (max-width: 500px) {
        font-size: 14px;
      }
    }

    thead {
      background-color: $backGroundSecondary;
      text-align: center;
      font-weight: 500;
      border: 1px solid #d7d7d7;
    }
    th,
    td {
      padding: 25px 10px;
      text-transform: capitalize;
      @media screen and (max-width: 760px) {
        padding: 15px 20px;
      }
    }
    tbody tr {
      border: 1px solid #d7d7d7;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      font-style: normal;
      text-align: center;
      @media screen and (max-width: 500px) {
        font-size: 14px;
      }
    }
    .view {
      color: $dark;
    }
    .view-details {
      text-align: center;
    }
    tbody tr:nth-of-type(even) {
      background-color: $backGroundSecondary;
    }
    tbody tr:hover {
      background-color: #e4e6e9;
    }
   
    .fixedd {
      padding: 8px 29px;
     background-color: #fff5cc;
     color: #000000;
     border-radius: 4px;
     @media screen and (max-width: 768px) {
       padding: 4px 23px;
     }
   }
    .dynamic {
      background-color: #cfcffc;
      color: #000000;
      border-radius: 4px;
      padding: 8px 15px;
      @media screen and (max-width: 768px) {
        padding: 4px 12px;
      }
    }
    .transfer {
      color: #000000;
      background-color: #ccffe6;
      border-radius: 4px;
      padding: 8px 12px;
      @media screen and (max-width: 768px) {
        padding: 4px 6px;
      }
    }
    .inactive {
      padding: 8px 29px;
     background-color: #fff5cc;
     color: #000000;
     border-radius: 4px;
   
     @media screen and (max-width: 768px) {
       padding: 4px 13px;
     }
   }
   .active {
    padding: 8px 29px;
   background-color: #fff5cc;
   color: #000000;
   border-radius: 4px;
 
   @media screen and (max-width: 768px) {
     padding: 4px 13px;
   }
 }

    .flex_copy {
     position: relative;
     cursor: pointer;
     font-weight: 400;
    }

    .inactive_link {
      color: #000000;
      cursor: pointer;
      background-color: #ffc1c1;
      border-radius: 4px;
      padding: 8px 10px;
      @media screen and (max-width: 768px) {
        padding: 4px 6px;
      }
    }

    .active_link {
      color: #000000;
      cursor: pointer;
      background-color: #b3ffda;
      border-radius: 4px;
      padding: 8px 16px;
      @media screen and (max-width: 768px) {
        padding: 4px 10px;
      }
    }

    .copy__icon {
      cursor: pointer;
      position: absolute;
      top: 35%;
      right: 18%;
      @media screen and (max-width: 768px) {
        padding: 4px 10px;
      }
    }

    .hide {
      display: none;
     }
  }

  .payment-link-tabs {
    margin-top: 52px;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 16px;
    min-width: 1200px;
    width: 100%;
    @media screen and (max-width: 1500px) {
      min-width: 1300px;
    }
    @media screen and (max-width: 500px) {
      font-size: 14px;
      margin: 30px 0;
    }
    td {
      font-weight: 500;
      color: $dark;
      @media screen and (max-width: 500px) {
        font-size: 14px;
      }
      .bank-details {
        display: grid;
        .bank-No {
          color: $dark-11;
          @extend %textVariationOne;
        }
      }
    }
    .bolder__one {
      font-weight: 700;
      color: $dark;
      @media screen and (max-width: 500px) {
        font-size: 14px;
      }
    }
    .bolder__two {
      font-weight: 700;
      color: red;
      @media screen and (max-width: 500px) {
        font-size: 14px;
      }
    }

    thead {
      background-color: $backGroundSecondary;
      text-align: center;
      font-weight: 500;
      border: 1px solid #d7d7d7;
    }
    th,
    td {
      padding: 25px 10px;
      text-transform: capitalize;
      @media screen and (max-width: 760px) {
        padding: 15px 20px;
      }
    }


    tbody tr {
      border: 1px solid #d7d7d7;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      font-style: normal;
      text-align: center;
     
      @media screen and (max-width: 500px) {
        font-size: 14px;
      }
    }
    .view {
      color: $dark;
    }
    .view-details {
      text-align: center;
    }
    tbody tr:nth-of-type(even) {
      background-color: $backGroundSecondary;
    }
    tbody tr:hover {
      background-color: #e4e6e9;
    }
   
    .fixedd {
      padding: 8px 29px;
     background-color: #fff5cc;
     color: #000000;
     border-radius: 4px;
     @media screen and (max-width: 768px) {
       padding: 4px 23px;
     }
   }
   
    .dynamic {
      background-color: #cfcffc;
      color: #000000;
      border-radius: 4px;
      padding: 8px 15px;
      @media screen and (max-width: 768px) {
        padding: 4px 12px;
      }
    }

    .transfer {
      color: #000000;
      background-color: #ccffe6;
      border-radius: 4px;
      padding: 8px 12px;
      @media screen and (max-width: 768px) {
        padding: 4px 6px;
      }
    }


    .edit-fees {
      color: #595959;
      background-color: #ffffff;
      border: 1px solid #D9D9D9;
      border-radius: 8px;
      margin-right: 16px;
      padding: 8px 12px;
      @media screen and (max-width: 768px) {
        padding: 4px 6px;
      }
    }

    .delete-fees {
      color: #fff;
      background-color: red;
      border-radius: 8px;
      padding: 8px 12px;
      @media screen and (max-width: 768px) {
        padding: 4px 6px;
      }
    }

    
    
    

  //   .dynamic-transfer {
  //     //padding: 8px 10px;
  //    background: red;
  //    color: #000000;
  //    //border-radius: 4px;
  //    @media screen and (max-width: 768px) {
  //      padding: 4px 23px;
  //    }
  //  }

    .inactive {
      padding: 8px 29px;
     background-color: #fff5cc;
     color: #000000;
     border-radius: 4px;
   
     @media screen and (max-width: 768px) {
       padding: 4px 13px;
     }
   }
   .active {
    padding: 8px 29px;
   background-color: #fff5cc;
   color: #000000;
   border-radius: 4px;
 
   @media screen and (max-width: 768px) {
     padding: 4px 13px;
   }
 }

    .flex_copy {
     position: relative;
     cursor: pointer;
     font-weight: 500;
     padding-right: 60px;
      font-size: 16px;
      line-height: 24px;
      font-style: normal;
      text-align: center;
    }

    .inactive_link {
      color: #000000;
      cursor: pointer;
      background-color: #ffc1c1;
      border-radius: 4px;
      padding: 8px 10px;
      @media screen and (max-width: 768px) {
        padding: 4px 6px;
      }
    }

    .active_link {
      color: #000000;
      cursor: pointer;
      background-color: #b3ffda;
      border-radius: 4px;
      padding: 8px 16px;
      @media screen and (max-width: 768px) {
        padding: 4px 10px;
      }
    }

    .copy__icon {
      cursor: pointer;
      position: absolute;
      top: 38%;
      right: 18%;
      padding-right: 50px;
      @media screen and (max-width: 1600px) {
        padding-right: 40px;
      }
    }

    .hide {
      display: none;
     }
  }
}
#table-container::-webkit-scrollbar {
  display: none;
}
