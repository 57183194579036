@import "../../../../assests/stylesheets/common/variables";

.subaccount__main {
  margin-top: 30px;
  padding: 0 50px;
  @media screen and (max-width: 768px) {
    padding: 0 24px;
    margin-top: 24px;
  }
  .main-sub {
    .header-container {
      @extend %flex-justify-between;
      h1 {
        @extend %textVariationFour;
        @extend %flex;
        .home3-sub {
          @extend %flex;
          background: $primaryColor;
          @extend %textVariationOne;
          color: $white;
          border-radius: 4px;
          padding: 0 15px;
          width: 32px;
          height: 32px;
          margin-left: 0.5rem;
          @media screen and (max-width: 500px) {
            padding: 0 8px;
            width: auto;
            height: 25px;
          }
        }
      }
      p {
        color: $grey;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }
    }
    .subHeader-right {
      @extend %flex-justify-between;
      .btn-div {
        @extend %flex;
        @media screen and (max-width: 970px) {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
        button {
          @media screen and (max-width: 500px) {
            margin-top: -8px;
          }
        }
        .new-img {
          margin-right: 10px;
          margin-top: -2px;
          font-size: 20px;
          @media screen and (max-width: 480px) {
            font-size: 17px;
          }
        }
      }
    }
  }
  .no__Data {
    @extend %flex-col;
    margin-top: 162px;
    @media screen and (max-width: 480px) {
      margin-top: 80px;
    }
    h2 {
      @extend %textVariationNoData;
      margin-top: 26px;
    }
    h4 {
      @extend %textVariationTwoone;
      color: $grey;
      margin-top: -5px;
      max-width: 45%;
      text-align: center;
      @media screen and (max-width: 480px) {
        max-width: 100%;
      }
    }
  }
  .transactionTable {
    .pagination--Details {
      @extend %flex;
      justify-content: space-between;
      padding: 48px 28px 16px 28px;
      border: 1px solid #d7d7d7;
      margin-top: -25px;
      margin-bottom: 36px;
      @media screen and (max-width: 480px) {
        padding: 15px 10px;
        margin-bottom: 30px;
      }
      .items-to-show {
        @extend %flex;
        width: 240px;
        position: relative;
        p {
          color: #595959;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          width: 100%;
          height: 24px;
          line-height: 24px;
          @media screen and (max-width: 500px) {
            display: none;
          }
        }
        select {
          background: $light-grey;
          border-radius: 8px;
          padding: 0 18px;
          height: 40px;
          appearance: none;
          color: $dark;
          border: none;
          margin-bottom: 16px;
          width: 130px;
          height: 48px;
          @media screen and (max-width: 500px) {
            height: 35px;
            width: 50px;
            padding: 0 8px;
            margin-right: 180px;
          }
          @media screen and (max-width: 395px) {
            display: none;
          }
          option {
            @extend %textVariationTwo;
            padding: 10px 5px;
          }
        }
        .business-down {
          position: absolute;
          display: block;
          right: 16px;
          top: 15px;
          color: $dark;
          font-size: 20px;
          pointer-events: none;
          @media screen and (max-width: 500px) {
            right: 190px;
            top: 10px;
            font-size: 18px;
          }
          @media screen and (max-width: 395px) {
            display: none;
          }
        }
      }
      .pagination__container {
        background: $white;
        list-style: none;
        display: flex;
        align-items: center;
      }
      .pagination__container a {
        width: 40px;
        height: 40px;
        background-color: $white;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        margin-right: 10px;
        color: $grey;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-align: center;
        @extend %flex;
        cursor: pointer;
        &:hover {
          background: #f7f7f7;
          color: $grey;
          border: none;
        }
        @media screen and (max-width: 500px) {
          font-size: 12px;
          width: 24px;
          height: 24px;
          margin-right: 4px;
        }
      }
      .pages-p {
        width: 40px;
        height: 40px;
        background-color: $white;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        margin-right: 10px;
        color: $grey;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-align: center;
        @extend %flex;
        cursor: pointer;
        @media screen and (max-width: 500px) {
          font-size: 12px;
          width: 24px;
          height: 24px;
          margin-right: 4px;
        }
      }
      .active a {
        width: 40px;
        height: 40px;
        background-color: $primaryColor;
        border-radius: 4px;
        margin-right: 10px;
        color: $white;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-align: center;
        @extend %flex;
        cursor: pointer;
        @media screen and (max-width: 500px) {
          font-size: 12px;
          width: 24px;
          height: 24px;
          margin-right: 4px;
        }
      }
    }
  }
}
