@import "../../../../assests/stylesheets/common/variables";

.main-trans {
  margin-top: 30px;
  padding: 0 50px;
  @media screen and (max-width: 768px) {
    padding: 0 24px;
    margin-top: 24px;
  }
  .status__content {
    @extend %flex-col;
    form {
      max-width: 458px;
      height: auto;
      width: 100%;
      background: $white;
      display: grid;
      box-shadow: $boxShadow3;
      padding-left: 0;
      border-radius: 24px;
      color: $secondaryColor;
      border: none;
      z-index: 1;
      margin-bottom: 10px;
      margin-top: 10px;
      .successful--svg {
        align-self: center;
        justify-self: center;
        margin-top: 90px;
        margin-bottom: 13px;
        @media screen and (max-width: 500px) {
          margin-top: 50px;
        }
      }
      .btm__line {
        width: 100%;
        border-bottom: 1px solid $light-grey;
        padding-bottom: 2px;
        h2 {
          @extend %textVariationThree;
          color: $dark;
          margin-top: 24px;
          margin-bottom: 16px;
          @extend %flex;
        }
      }
      h3 {
        font-size: 28px;
        line-height: 120%;
        font-style: normal;
        font-weight: 400;
        color: #1a1a1a;
        margin-bottom: 56px;
        align-self: center;
        justify-self: center;
        @media screen and (max-width: 500px) {
          font-size: 24px;
          margin-bottom: 40px;
        }
      }
      .card_details {
        height: 330px;
        max-width: 414px;
        @extend %flexx;
        background: #fafafa;
        margin-left: auto;
        margin-right: auto;
        padding: 32px 24px;
        border-radius: 16px;
        margin-bottom: 32px;
        @media screen and (max-width: 500px) {
          padding: 32px 10px;
          width: 95%;
          margin-left: auto;
          margin-right: auto;
          height: auto;
        }
        div {
          @extend %flex;
          justify-content: space-between;
          .payer--section {
            @extend %textVariationTwoone;
            color: #838383;
            margin-bottom: 24px;
            @media screen and (max-width: 480px) {
              padding-right: 10px;
            }
          }
          .right--section {
            @extend %textVariationTwoone;
            color: #4a4a4a;
            margin-bottom: 24px;
          }
          p {
            @extend %textVariationTwoone;
            color: #838383;
            margin-bottom: 24px;
          }

          .trans--section {
            @extend %textVariationTwoone;
            color: #838383;
            margin-bottom: 24px;
            padding-right: 25px;
            @media screen and (max-width: 480px) {
              padding-right: 10px;
            }
          }
        }
      }
    }
  }
}
