@import "../../assests/stylesheets/common/variables";

.settlement {
  margin-top: 30px;
  padding: 0 50px;
  @media screen and (max-width: 768px) {
    padding: 0 24px;
    margin-top: 24px;
  }
  .main-settle {
    .header-container {
      @extend %flex-justify-between;
      h1 {
        @extend %textVariationFour;
        @extend %flex;
        .home3-sub {
          @extend %flex;
          background: $primaryColor;
          @extend %textVariationOne;
          color: $white;
          border-radius: 4px;
          padding: 0 15px;
          width: 32px;
          height: 32px;
          margin-left: 0.5rem;
          @media screen and (max-width: 500px) {
            padding: 0 8px;
            width: 25px;
            height: 25px;
          }
        }
      }
    }
    .subHeader-right {
      @extend %flex-justify-between;
    }
    .settlement-card {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1.6rem;
      margin-top: 40px;
      @media screen and (max-width: 1150px) {
        grid-template-columns: repeat(1, 1fr);
      }
      .total-card {
        background: $blue-50;
        border-radius: 16px;
        color: $dark;
        height: 119px;
        max-width: 3000px;
        width: 100%;
        @media screen and (max-width: 2500px) {
          max-width: 2500px;
        }
        @media screen and (max-width: 1500px) {
          max-width: 1390px;
        }
        @media screen and (max-width: 1000px) {
          max-width: 620px;
        }
        @media screen and (max-width: 768px) {
          max-width: 715px;
        }
        @media screen and (max-width: 650px) {
          max-width: 600px;
        }
        @media screen and (max-width: 600px) {
          max-width: 700px;
        }
        @media screen and (max-width: 414px) {
          max-width: 365px;
        }
        .main-title {
          margin-top: 30px;
          margin-left: 32px;
          @media screen and (max-width: 480px) {
            margin-left: 15px;
          }
          .main-descr {
            color: #002766;
            width: 155px;
            font-style: normal;
            @extend %textVariationThree;
          }
        }
        .card-dsicrption {
          margin-top: 10px;
        }
        .description {
          display: flex;
          justify-content: space-between;
          margin-top: 5px;
          margin-left: 32px;
          @media screen and (max-width: 480px) {
            margin-left: 15px;
          }
          p {
            @extend %textVariationFive;
            color: #002766;
          }
          .second {
            margin-right: 32px;
          }
        }
      }
      .bank-cards {
        background: $white;
        border-radius: 16px;
        color: $dark;
        border: 1px solid #d7d7d7;
        height: 119px;
        max-width: 3000px;
        width: 100%;
        left: 591px;
        top: 120px;
        @media screen and (max-width: 2500px) {
          max-width: 2500px;
        }
        @media screen and (max-width: 1500px) {
          max-width: 1390px;
        }
        @media screen and (max-width: 1000px) {
          max-width: 620px;
        }
        @media screen and (max-width: 768px) {
          max-width: 715px;
        }
        @media screen and (max-width: 650px) {
          max-width: 600px;
        }
        @media screen and (max-width: 600px) {
          max-width: 700px;
        }
        @media screen and (max-width: 414px) {
          max-width: 365px;
        }
        .description2 {
          display: flex;
          justify-content: space-between;
          margin-top: 35px;
          margin-left: 100px;
          position: relative;
          @media screen and (max-width: 480px) {
            margin-left: 85px;
          }
          .bank__icons {
            position: absolute;
            left: -70px;
          }
          img {
            position: absolute;
            left: -70px;
          }
          span {
            display: grid;
            .bank {
              @extend %textVariationThree;
              color: $dark;
            }
            .accNo {
              @extend %textVariationThree;
              margin-top: -15px;
              color: $grey;
            }
          }
          .second {
            margin-right: 32px;
          }
          button {
            padding: 14px;
            display: flex;
            // height: 48px;
            background: $green;
            border-radius: 8px;
            border: none;
            color: $white;
            font-size: 16px;
            font-weight: 600;
            line-height: 16px;
            margin-right: 40px;
            cursor: pointer;
            @media screen and (max-width: 480px) {
              margin-right: 15px;
              padding: 8px 12px;
              font-size: 12px;
              font-weight: 500;
            }
            &:hover {
              background: #6baa74;
              color: $white;
            }
            .edit {
              padding-left: 8px;
              @media screen and (max-width: 480px) {
                padding-left: 4px;
              }
            }
          }
        }
      }
      .transaction-title {
        margin-top: 24px;
        margin-left: 32px;
        color: #002766;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        left: 623px;
        top: 184px;
      }
      .transaction-summary {
        border-bottom: 1px dashed #d7d7d7;
        padding-bottom: 5px;
      }
    }
  }
  .noData {
    @extend %flex-col;
    margin-top: 162px;
    @media screen and (max-width: 480px) {
      margin-top: 80px;
    }
    h2 {
      @extend %textVariationNoData;
      margin-top: 26px;
    }
    h4 {
      @extend %textVariationTwoone;
      color: $grey;
      margin-top: -10px;
      max-width: 45%;
      text-align: center;
      @media screen and (max-width: 480px) {
        max-width: 100%;
      }
    }
  }
}
