@import "../../assests/stylesheets/common/variables";

.account-main {
  margin-top: 30px;
  padding: 0 50px;
  @media screen and (max-width: 768px) {
    padding: 0 24px;
    margin-top: 24px;
  }
  .row {
    display: flex;
    margin: 0 -15px -15px -15px;
    flex-wrap: wrap;
    align-items: center;

    .column {
      margin-bottom: 15px;
      padding-right: 15px;
      padding-left: 15px;
      flex: 1;
      max-width: 50%;
      flex-basis: 50%;

      @media screen and (max-width: 1200px) {
        max-width: 100%;
        flex-basis: 100%;
        display: flex;
        justify-content: center;
      }

      .account-wrap {
        @media screen and (max-width: 768px) {
          margin-top: 2rem;
        }
        h3 {
          color: $dark;
          @extend %textVariationThreeone;
          margin-top: 1rem;
        }
        form {
          max-width: 520px;
          height: auto;
          width: 100%;
          display: grid;
          padding: 16px 32px;
          border-radius: 8px;
          color: $secondaryColor;
          border: none;
          z-index: 1;
          box-shadow: $boxShadow2;
          background: $white;
          margin-top: 28px;

          .iimg {
            @extend %flexself;
            padding-top: 90px;
            @media screen and (max-width: 480px) {
              padding-top: 35px;
            }
          }
          h4 {
            color: $dark;
            @extend %flexself;
            @extend %textVariationFour;
            margin-top: 35px;
            margin-bottom: 8px;
            text-align: center;
          }
          p {
            color: $grey;
            @extend %flexself;
            @extend %textVariationTwoone;
            margin-bottom: 2rem;
          }
          h5 {
            color: #282828;
            @extend %flexself;
            @extend %textVariationTwoone;
            text-align: center;
            padding: 0 24px;
            @media screen and (max-width: 980px) {
              padding: 0;
              text-align: center;
            }
          }
          button {
            @extend %inputTwo;
            @extend %textVariationbtn;
            margin-top: 2rem;
            margin-bottom: 24px;
            margin-right: 24px;
            width: 90%;
            margin-left: 24px;
            @media screen and (max-width: 980px) {
              margin-right: 4px;
              margin-left: 4px;
              width: 95%;
            }
          }
        }
      }
    }
  }
}
