@import "../../assests/stylesheets/common/variables";

.personal-main {
  margin-top: 30px;
  padding: 0 50px;
  @media screen and (max-width: 768px) {
    padding: 0 24px;
    margin-top: 24px;
  }
  .row {
    display: flex;
    margin: 0 -15px -15px -15px;
    flex-wrap: wrap;
    align-items: center;
    .account-play {
      margin-left: 30rem;
      margin-top: -10rem;
      position: absolute;
      color: $secondaryColor;
      font-size: 20px;
      @media screen and (max-width: 1380px) {
        display: none;
      }
    }
    .column {
      margin-bottom: 15px;
      padding-right: 15px;
      padding-left: 15px;
      flex: 1;
      max-width: 50%;
      flex-basis: 50%;
      @media screen and (max-width: 1200px) {
        max-width: 100%;
        flex-basis: 100%;
        display: flex;
        justify-content: center;
      }
    
      .account-wrap {
        margin-bottom: -18.3rem;
        @media screen and (max-width: 768px) {
          margin-top: 2rem;
          margin-bottom: 0rem;
        }
        h3 {
          color: $dark;
          @extend %textVariationThreeone;
          margin-top: 1rem;
        }
        p {
          color: $grey;
          @extend %textVariationTwo;
          margin-top: 1rem;
          max-width: 500px;
        }
        form {
          max-width: 560px;
          height: auto;
          width: 100%;
          display: grid;
          padding: 32px 88px;
          border-radius: 8px;
          color: $secondaryColor;
          border: none;
          z-index: 1;
          box-shadow: $boxShadow2;
          background: $white;
          margin-top: 28px;
          @media screen and (max-width: 500px) {
            padding: 16px 32px;
          }
          .button {
            @extend %inputSixx;
            @extend %textVariationThree;
            margin-bottom: 8px;
            background: $backGroundSecondary;
            border: 1px dashed #7b7b7b;
            width: 100%;
            align-items: center;
            justify-content: center;
          }
          p {
            color: $grey;
            @extend %textVariationOne;
            margin-bottom: -22px;
          }
          label {
            color: $grey;
            @extend %textVariationOne;
            margin-bottom: 8px;
          }
          .business-option {
            width: 100%;
            position: relative;
            select {
              background: #f7f7f7;
              border-radius: 8px;
              padding: 0 16px;
              height: 56px;
              appearance: none;
              color: $grey;
              border: none;
              margin-bottom: 16px;
              width: 100%;
              &:focus {
                outline: none !important;
                border: 1.5px solid #1a71ff;
              }
              option {
                @extend %textVariationTwo;
                padding: 10px 5px;
              }
            }
            .business-down {
              position: absolute;
              display: block;
              right: 16px;
              font-size: 20px;
              top: 20px;
              pointer-events: none;
            }
          }
          .align-names {
            position: relative;
            .left-input {
              display: grid;
              width: 10px;
              @media screen and (max-width: 1080px) {
                width: 100%;
              }
            }
            .right-input {
              display: grid;
              width: 10px;
              position: absolute;
              left: 200px;
              top: 0px;
              @media screen and (max-width: 1080px) {
                width: 100%;
                position: relative;
                left: 0px;
                top: 0px;
              }
            }
          }
          input {
            @extend %inputOne;
            margin-bottom: 16px;
            color: $dark;
          }
          .personalInput {
            @extend %inputOne;
            margin-bottom: 16px;
          }
          .invalid {
            border: 1.5px solid red;
          }
          .invalid:focus {
            border: 1.5px solid red;
          }
          .upload {
            position: relative;
            .upload__btn {
              position: absolute;
              width: 100% !important;
              @extend %inputImg;
              @extend %textVariationbtn;
              margin-top: 1rem;
              margin-bottom: 24px;
              &:hover {
                color: $dark;
              }
            }
          }
          button {
            @extend %inputTwo;
            @extend %textVariationbtn;
            margin-top: 32px;
            margin-bottom: 24px;
            &:hover {
              color: $white;
            }
          }
        }
      }

      .upload-wrap {
        margin-bottom: -18.1rem;
        @media screen and (max-width: 768px) {
          margin-top: 2rem;
          margin-bottom: 0rem;
        }
        h3 {
          color: $dark;
          @extend %textVariationThreeone;
          margin-top: 1rem;
        }
        p {
          color: $grey;
          @extend %textVariationTwo;
          margin-top: 1rem;
          max-width: 500px;
        }
        form {
          max-width: 560px;
          height: auto;
          width: 100%;
          display: grid;
          padding: 32px 88px;
          border-radius: 8px;
          color: $secondaryColor;
          border: none;
          z-index: 1;
          box-shadow: $boxShadow2;
          background: $white;
          margin-top: 28px;
          @media screen and (max-width: 500px) {
            padding: 16px 32px;
          }

          .button {
            @extend %inputSixx;
            @extend %textVariationThree;
            margin-bottom: 8px;
            background: $backGroundSecondary;
            border: 1px dashed #7b7b7b;
            width: 100%;
            align-items: center;
            justify-content: center;
          }

          input[type="file"] {
            position: absolute;
            z-index: -1;
            top: 10px;
            left: 8px;
            font-size: 17px;
            color: #b8b8b8;
          }
          .btn-Wrap {
            position: relative;
          }

          .btn {
            display: inline-block;
            padding: 16px 145px;
            cursor: pointer;
            border-radius: 5px;
            width: 100%;
            font-size: 16px;
            background: $backGroundSecondary;
            border: 1px dashed #7b7b7b;
            color: $grey;
            &:hover {
              background: #f5f3f3;
            }
            @media screen and (max-width: 500px) {
              padding: 16px 100px;
            }
          }

          p {
            color: $grey;
            @extend %textVariationOne;
            margin-bottom: -22px;
          }
          label {
            color: $grey;
            @extend %textVariationOne;
            margin-bottom: 8px;
          }
          .business-option {
            width: 100%;
            position: relative;
            select {
              background: #f7f7f7;
              border-radius: 8px;
              padding: 0 16px;
              height: 56px;
              appearance: none;
              color: $grey;
              border: none;
              margin-bottom: 16px;
              width: 100%;
              &:focus {
                outline: none !important;
                border: 1.5px solid #1a71ff;
              }
              option {
                @extend %textVariationTwo;
                padding: 10px 5px;
              }
            }

            .business-down {
              position: absolute;
              display: block;
              right: 16px;
              font-size: 20px;
              top: 20px;
              pointer-events: none;
            }
          }

          .align-names {
            position: relative;
            .left-input {
              display: grid;
              width: 10px;
              @media screen and (max-width: 1080px) {
                width: 100%;
              }
            }
            .right-input {
              display: grid;
              width: 10px;
              position: absolute;
              left: 200px;
              top: 0px;

              @media screen and (max-width: 1080px) {
                width: 100%;
                position: relative;
                left: 0px;
                top: 0px;
              }
            }
          }

          input {
            @extend %inputOne;
            margin-bottom: 16px;
            color: $dark;
          }
          .personalInput {
            @extend %inputOne;
            margin-bottom: 16px;
          }
          .invalid {
            border: 1.5px solid red;
          }
          .invalid:focus {
            border: 1.5px solid red;
          }
          .upload {
            position: relative;
            .upload__btn {
              position: absolute;
              width: 100% !important;
              @extend %inputImg;
              @extend %textVariationbtn;
              margin-top: 1rem;
              margin-bottom: 24px;
              &:hover {
                color: $dark;
              }
            }
          }
          button {
            @extend %inputTwo;
            @extend %textVariationbtn;
            margin-top: 32px;
            margin-bottom: 24px;
            &:hover {
              color: $white;
            }
          }
        }
      }
    }
  }
}
