@import "../../assests/stylesheets/common/variables";

.login {
  min-height: 100vh;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  overflow: hidden;
  background: $backGroundPrimary;
  &-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 24px;

    .img {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      padding: 98px 0 40px 0;
      @media screen and (max-width: 480px) {
        padding: 70px 0 40px 0;
      }
    }
    .login-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      form1 {
        max-width: 448px;
        height: auto;
        width: 100%;
        display: grid;
        padding: 16px 32px 0px 32px;
        border-radius: 8px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        color: #002766;
        border-radius: 8px;
        border: 1px dashed #002766;
        z-index: 1;
        box-shadow: none;
        background: none;
        margin-bottom: 28px;
      }
      form {
        max-width: 448px;
        height: auto;
        width: 100%;
        display: grid;
        padding: 16px 32px;
        border-radius: 8px;
        color: #002766;
        border: none;
        z-index: 1;
        box-shadow: 0px 4px 32px rgba(223, 235, 255, 0.9);
        background: #ffffff;
        margin-bottom: 28px;
        section {
          font-size: 14px;
          color: red;
          font-weight: 400;
        }
        h2 {
          color: #002766;
          margin-top: 16px;
          margin-bottom: 8px;
          @extend %textVariationSix;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        p {
          display: flex;
          align-items: center;
          justify-content: center;
          @extend %textVariationzero;
          color: $grey-2;
          margin-bottom: 40px;
        }
        label {
          color: $grey;
          @extend %textVariationOne;
          margin-bottom: 8px;
        }
        .loginInput {
          @extend %inputOne;
          color: $grey;
          margin-bottom: 16px;
        }
        .invalid {
          border: 1.5px solid red;
        }
        .invalid:focus {
          border: 1.5px solid red;
        }

        .input {
          width: 100%;
          position: relative;
          .loginInput {
            @extend %inputOne;
            width: 100%;
          }
          .invalid {
            border: 1.5px solid red;
          }
          .invalid:focus {
            border: 1.5px solid red;
          }
          .logout-eye {
            position: absolute;
            display: block;
            right: 16px;
            top: 20px;
            cursor: pointer;
            font-size: 20px;
            color: $grey;
          }
        }
        button {
          @extend %inputTwo;
          @extend %textVariationbtn;
          margin-top: 24px;
          margin-bottom: 16px;
          cursor: pointer;
          .sign {
            color: $white;
          }
        }
        h4 {
          @extend %flex;
          @extend %textVariationOneone;
          color: $dark-110;
          padding: 8px 0 24px 0;
          &:hover {
            color: $primaryColor;
          }
          .forgt {
            color: $grey;
            &:hover {
              color: $primaryColor;
            }
          }
        }
      }
      .login-create {
        @extend %flex;
        @extend %inputThree;
        max-width: 310px;
        height: 48px;
        margin-left: 3.5rem;
        display: grid;
        margin-bottom: 80px;
        @media screen and (max-width: 480px) {
          margin-left: 1.5rem;
        }
        @media screen and (max-width: 375px) {
          margin-left: 0.5rem;
        }
        p {
          color: $grey;
          span {
            margin-left: 5px;
            color: $primaryColor;
            .link {
              color: $primaryColor;
            }
          }
        }
      }
    }
  }
}
