@import "../../assests/stylesheets/common/variables";

.register {
  min-height: 100vh;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  overflow: hidden;
  background: $backGroundPrimary;
  .link {
    color: $primaryColor;
  }
  &-wrap {
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;

    .row {
      display: flex;
      margin: 0 -15px -15px -15px;
      flex-wrap: wrap;
      align-items: center;
      .column {
        margin-bottom: 15px;
        padding-right: 15px;
        padding-left: 15px;
        flex: 1;
        max-width: 50%;
        flex-basis: 50%;
        @media screen and (max-width: 768px) {
          max-width: 100%;
          flex-basis: 100%;
          display: flex;
          justify-content: center;
        }
        .image-wrap {
          margin-top: -17rem;
          @media screen and (max-width: 768px) {
            margin-top: 4rem;
          }
          h2 {
            color: $secondaryColor;
            margin-top: 37px;
            font-weight: 600;
            font-size: 34px;
            line-height: 48px;
          }
          p {
            margin-top: -8px;
            color: $secondaryColor;
            @extend %textVariationTwo;
          }
        }
        .wrapper {
          padding-top: 5rem;
          flex-direction: column;
          @extend %flex;
          height: 100%;
          @media screen and (max-width: 768px) {
            margin-top: -3rem;
          }
          form {
            max-width: 500px;
            height: auto;
            width: 100%;
            position: relative;
            display: grid;
            padding: 16px 32px;
            border-radius: 8px;
            color: $secondaryColor;
            border: none;
            z-index: 1;
            box-shadow: $boxShadow;
            background: $white;
            margin-bottom: 28px;
            section {
              font-size: 14px;
              padding-bottom: 8px;
              color: red;
              font-weight: 400;
            }
            .label-top {
              padding-top: 20px;
            }
            label {
              color: $grey;
              @extend %textVariationOne;
              margin-bottom: 8px;
            }
              .left-input {
               display: grid;
                width: 210px;
                @media screen and (max-width: 1080px) {
                  width: 100%;
                }
              }           
              .right-input {
                display: grid;
                width: 210px;
                position: absolute;
                left: 260px;
                top: 133px;
                @media screen and (max-width: 1080px) {
                  width: 100%;
                  position: relative;
                  left: 0px;
                  top: 0px;
                }
                .label {
                  color: $grey;
                  @extend %textVariationOne;
                  margin-bottom: 8px;
                }
              }
            .select-option {
              width: 100%;
              position: relative;
              select {
                background: #f7f7f7;
                border-radius: 8px;
                padding: 0 16px;
                height: 56px;
                appearance: none;
                color: $grey;
                border: none;
                margin-bottom: 16px;
                width: 100%;
                &:focus {
                  outline: none !important;
                  border: 1.5px solid #1a71ff;
                }
                option {
                  @extend %textVariationTwo;
                  padding: 10px 5px;
                }
              }
              .select-down {
                position: absolute;
                display: block;
                right: 16px;
                font-size: 20px;
                top: 20px;
                pointer-events: none;
              }
            }
            .input {
              width: 100%;
              position: relative;
              input {
                @extend %inputOne;
                margin-bottom: 24px;
                width: 100%;
                cursor: pointer;
              }
              .invalid {
                border: 1.5px solid red;
              }
              .invalid:focus {
                border: 1.5px solid red;
              }
              .input-eye {
                position: absolute;
                display: block;
                right: 16px;
                top: 20px;
                cursor: pointer;
                font-size: 20px;
                color: $grey;
              }
            }
            .registerInput {
              @extend %inputOne;
              margin-bottom: 16px;
            }
            .invalid {
              border: 1.5px solid red;
            }
            .invalid:focus {
              border: 1.5px solid red;
            }

            button {
              @extend %inputTwo;
              @extend %textVariationbtn;
              margin-top: 24px;
              margin-bottom: 24px;
              .links {
                color: $white;
              }
            }
            .check-Box {
              @extend %textVariationOne;
              display: flex;
              position: relative;
              margin-bottom: 24px;
              align-items: center;
              justify-content: center;

              .register__checkbox {
                color: $primaryColor;
                cursor: pointer;
                left: 0;
                top: 7px;
                background: #fff;
                position: absolute;
                border-radius: 2px;
                height: 20px;
                width: 20px;
                border: 0.8px solid $primaryColor;
              }
              .register__checkbox:focus {
                outline: none !important;
                border: 1.5px solid #1a71ff;
                background: #f7f7f7;
              }
              .invalid {
                border: 0.8px solid red;
              }
              .invalid:focus {
                border: 0.8px solid red;
              }
            }
            p {
              margin-left: 30px;
              .privacy-shift {
                margin: 0 5px;
              }
            }
          }
          .register-create {
            @extend %flex;
            @extend %inputThree;
            max-width: 310px;
            margin-left: 0rem;
            height: 48px;
            display: grid;
            margin-bottom: 80px;
            @media screen and (max-width: 375px) {
              margin-left: 0.5rem;
            }
            p {
              color: $grey;
              span {
                margin-left: 5px;
                color: $primaryColor;
              }
            }
          }
        }
      }
    }
    .login-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .login-create {
        @extend %flex;
        @extend %inputThree;
        max-width: 310px;
        margin-left: 3.5rem;
        display: grid;
        margin-bottom: 40px;
        @media screen and (max-width: 480px) {
          margin-left: 1.5rem;
          padding: 16px;
        }
        @media screen and (max-width: 375px) {
          margin-left: 0.5rem;
        }
        p {
          color: $grey;
          span {
            color: $primaryColor;
          }
        }
      }
    }
  }
}
