@import "../../../../assests/stylesheets/common/variables";

.main__collect {
  margin-top: 30px;
  padding: 0 50px;
  @media screen and (max-width: 768px) {
    padding: 0 24px;
    margin-top: 24px;
  }
  .collect--pay {
    @extend %textVariationFour;
    color: $dark;
  }
  section {
    @extend %flex-col;
    .charge__form {
      max-width: 446px;
      height: auto;
      width: 100%;
      display: grid;
      border-radius: 24px;
      border: none;
      background: $white;
      box-shadow: $boxShadow3;
      margin-top: 10px;
      margin-bottom: 2.5rem;
      @media screen and (min-width: 1650px) {
        max-width: 550px;
      }
      .collect-line {
        width: 100%;
        border-bottom: 1px solid #d7d7d7;
        padding-bottom: 8px;
        h4 {
          @extend %textVariationTwoone;
          color: $grey;
          margin-top: 16px;
          @extend %flex;
          @media screen and (max-width: 480px) {
            margin-top: 1.5rem;
          }
        }
      }
      .amount--card {
        width: 100%;
        display: grid;
        align-items: center;
        padding: 24px 56px;
        @media screen and (max-width: 480px) {
          padding: 24px 30px;
        }
        label {
          color: $grey;
          @extend %textVariationOne;
          margin-bottom: 8px;
        }
        .input {
          background: $backGroundSecondary;
          border-radius: 8px;
          border: 2px solid $backGroundSecondary;
          padding: 0px 8px;
          color: $grey;
          width: 100%;
          font-weight: 400;
          font-size: 16px;
          align-self: center;
          @extend %textVariationFour;
          height: 56px;
          @media screen and (max-width: 480px) {
            font-size: 18px;
            line-height: 20px;
            width: 100%;
          }
          &:focus {
            outline: none !important;
            background: $white;
          }
        }
        .bankInput {
          @extend %inputOne;
          margin-bottom: 16px;
        }
        .invalid {
          border: 1.5px solid red;
        }
        .invalid:focus {
          border: 1.5px solid red;
        }
      }
      .enter__amount {
        width: 100%;
        color: $dark-110;
        @extend %textVariationTwo;
        margin-top: 16px;
      }
      .chargE {
        width: 100%;
        max-width: 100%;
        @extend %inputTwo;
        @extend %textVariationbtn;
        margin-top: 40px;
        @media screen and (max-width: 480px) {
          margin-top: 30px;
        }
      }
    }
  }

  .collect--payment {
    @extend %flex;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
      padding: 0 2px;
    }
    h3 {
      @extend %textVariationFour;
      @extend %flex;
      .home3-sub {
        @extend %flex;
        background: $primaryColor;
        @extend %textVariationOne;
        color: $white;
        border-radius: 4px;
        width: 26px;
        height: 26px;
        margin-left: 0.5rem;
      }
    }
    .topbar {
      .top-notify {
        .mini-pay {
          @extend %flex;
          background: $white;
          border: 1px solid #d9d9d9;
          border-radius: 8px;
          padding: 0 16px;
          height: 40px;
          cursor: pointer;
          &:hover {
            background: #d9d9d9;
          }
          @media screen and (max-width: 500px) {
            padding: 0 10px;
            height: 38px;
          }
          .check__trans {
            color: $grey;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            @media screen and (max-width: 500px) {
              font-size: 12px;
          }
          }
          .percentage {
            color: $white;
          }
        }
      }
    }
  }
}
