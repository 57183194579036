@import "../../../../assests/stylesheets/common/variables";

.main__stickyM {
  margin-top: 25px;
  padding: 0 50px;
  @media screen and (max-width: 768px) {
    padding: 0 24px;
    margin-top: 24px;
  }
  .payment-stand {
    @extend %flex-justify-between;
    .header-left {
      @extend %flex;
      #checkbox {
        width: 20px;
      }
      .inputs {
        margin-left: 35.33px;
      }
      span {
        @extend %textVariationTwoone;
        margin-left: 8px;
        margin-right: 16px;
      }
    }
    .icons {
      @extend %flex;
    }
    .filter-btn {
      width: auto;
      height: 48px;
      top: 32px;
      left: 934px;
      background-color: $white;
      border: 1px solid #d9d9d9;
      border-radius: 8px;
      padding: 16px;
      margin-right: 16px;
      color: $grey;
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      text-align: center;
      @extend %flex;
      cursor: pointer;
      @media screen and (max-width: 960px) {
        display: none;
      }
      .save {
        margin-left: 10px;
        color: $primaryColor;
        @extend %textVariationTwotwo;
      }
    }
  }

  .form {
    max-width: 650px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    background: #ffffff;
    display: grid;
    box-shadow: none;
    position: relative;
    padding: 0;
    border-radius: 8px;
    color: $secondaryColor;
    border: none;
    z-index: -10;
    @extend %flex;
    margin-top: -1450px;
    @media screen and (max-width: 500px) {
      margin-top: -990px;
    }
    .images {
      position: relative;
      object-fit: cover;
      width: 100%;
      max-width: 100%;
      height: auto;
      @media screen and (max-width: 500px) {
        margin-top: 10px;
      }
    }
    .new_card {
      background: white;
      position: absolute;
      max-width: 500px;
      top: 19%;
      left: 22.5%;
      height: auto;
    
      h1 {
        font-size: 54px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 2px;
        color: $dark;
        @extend %flex;
      }
      h3 {
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        color: $dark;
        margin-top: -50px;
        letter-spacing: 0.5px;
        @extend %flex;
      }
      h4 {
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        color: $primaryColor;
        margin-top: -28.5px;
        letter-spacing: 0.5px;
        @extend %flex;
      }
      h5 {
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        margin-top: -30px;
        color: $primaryColor;
        letter-spacing: 0.5px;
        @extend %flex;
      }
    }
    .qr_card {
      position: absolute;
      top: 58.4%;
      left: 25.9%;
      @media screen and (max-width: 675px) {
        max-width: 100%;
        top: 58.4%;
        left: 25%;
      }
      @media screen and (max-width: 600px) {
        max-width: 100%;
        top: 56.4%;
        left: 21.5%;
      }
      @media screen and (max-width: 480px) {
        max-width: 100%;
        top: 57.6%;
        left: 23.7%;
      }
      @media screen and (max-width: 414px) {
        max-width: 100%;
        top: 54.6%;
        left: 19%;
      }
    }
  }
  .sticker__ {
    margin-top: 390px;
    @extend %flex;
    form {
      max-width: 630px;
      height: auto;
      width: 100%;
      background: $white;
      box-shadow: $boxShadow3;
      border-radius: 8px;
      color: $secondaryColor;
      border: none;
      z-index: 1;
      margin-bottom: 10px;
      padding: 0 30px;
      margin-top: 10px;
      @media screen and (max-width: 960px) {
        margin-top: 30px;
        padding: 0 10px;
      }
      .bottom-line {
        width: 100%;
        border-bottom: 1px solid $light-grey;
        padding-bottom: 2px;
      }
      h3 {
        @extend %textVariationFour;
        color: $dark;
        margin-top: 24px;
        @extend %flex;
        @media screen and (max-width: 500px) {
          margin-top: 5px;
        }
      }
      h6 {
        @extend %textVariationTwo;
        color: $dark;
        @extend %flex;
        @media screen and (max-width: 500px) {
          margin-top: 5px;
        }
      }
      h4 {
        @extend %textVariationThree;
        color: #a4a4a4;
        margin-top: 30px;
        @extend %flex;
        text-align: center;
      }
      h2 {
        font-size: 28px;
        line-height: 120%;
        font-style: normal;
        font-weight: 400;
        color: #1a1a1a;
        @extend %flex;
        text-align: center;
        @media screen and (max-width: 500px) {
          font-size: 22px;
        }
      }
      card {
        max-width: 270px;
        height: auto;
        margin: 0 auto;
        width: 100%;
        display: grid;
        background: #f5f9ff;
        align-content: center;
        box-shadow: none;
        margin-top: 45px;
        padding: 16px 16px;
        div {
          @extend %flex;
          margin-top: 15px;
          .nibss {
            padding-right: 20px;
          }
        }
      }
      h5 {
        font-size: 20px;
        line-height: 26px;
        margin-top: 27px;
        font-style: normal;
        color: $dark-110;
        font-weight: 450;
        @extend %flex;
        @media screen and (max-width: 500px) {
          font-size: 16px;
        }
      }
      .line--wrapper {
        h6 {
          font-size: 16px;
          line-height: 24px;
          font-style: normal;
          margin-top: 5px;
          margin-bottom: 30px;
          font-weight: 500;
          color: $dark-110;
          display: flex;
          flex-direction: row;
        }
        h6:before,
        h6:after {
          content: "";
          flex: 1 1;
          border-bottom: 2px solid $light-grey;
          margin: auto 100px;
        }
        h6:before {
          margin-right: 10px;
        }
        h6:after {
          margin-left: 10px;
        }
      }
      .line__wrapper2 {
        h6 {
          font-size: 16px;
          line-height: 24px;
          font-style: normal;
          margin-top: 5px;
          margin-bottom: 30px;
          font-weight: 500;
          color: $dark-110;
          display: flex;
          flex-direction: row;
        }
        h6:before,
        h6:after {
          content: "";
          flex: 1 1;
          border-bottom: 1px solid #d7d7d7;
          margin: auto 50px;
        }
        h6:before {
          margin-right: 10px;
        }
        h6:after {
          margin-left: 10px;
        }
      }
      .transferPart {
        width: auto;
        height: auto;
        background: $lightPrimary;
        padding: 30px;
        margin-bottom: 35px;
        .gtPart {
          @extend %flex;
          justify-content: space-between;
          .payWith {
            @extend %textVariationSevenone;
            color: $dark;
            text-align: left;
            margin-bottom: 24px;
          }
          .accNo {
            @extend %textVariationFive;
            color: $primaryColor;
            text-align: right;
          }
          .wemaBank {
            @extend %textVariationTwotwo;
            color: $dark;
            text-align: right;
            margin-left: -200px;
          }
         }
      }
      p {
        font-size: 16px;
        width: 100%;
        line-height: 16px;
        font-style: normal;
        font-weight: 400;
        margin-top: 0px;
        text-align: left;
        color: $dark-110;
        @media screen and (max-width: 480px) {
          justify-content: flex-start;
          font-size: 14px;
        }
      }
    }
  }
}
