@import "../../assests/stylesheets/common/variables";

.navbar {
  display: none;
  @media screen and (max-width: 768px) {
    background: #f4f8ff;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  .navbar-container {
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 24px;
    padding-left: 24px;
    .nav-logo {
      justify-self: flex-start;
      cursor: pointer;
      text-decoration: none;
      font-size: 2rem;
      display: flex;
      align-items: center;
    }
    .mobile-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 55px;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
    .navbar-menu.active {
      transition: 350ms;
      left: 0;
    }
    .navbar-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 80px;
      opacity: 1;
      left: -100%;
      transition: all 0.5s ease;
      background: $backGroundPrimary;
      .dropdown--list {
        padding-left: 2rem;
        margin-top: 5px;
        list-style: none;
      }
      .navbar-dashboard {
        width: 100%;
        margin-bottom: 1rem;
        color: $dark;
        margin-top: 0.6rem;
        padding-left: 1rem;
        @extend %textVariationTwotwo;
        div {
          background-color: $backTetiary;
          padding: 0.6rem 7rem 0.6rem 1rem;
          border-radius: 8px;
          display: flex;
          span {
            display: flex;
            .navbar-image {
              border-radius: 50px;
              justify-content: center;
            }
          }
          .span {
            display: gird;
            position: relative;
            .userName {
              // margin-top: 4px;
              @extend %textVariationTwotwo;
              color: $dark;
            }
            .userRole {
              @extend %textVariationOne;
              color: $grey;
              position: absolute;
              margin-top: 22px;
              text-align: center;
            }
          }
          .navbar-merchant {
            margin-top: 3px;
          }
        }
      }
      li {
        text-decoration: none;
        list-style: none;
        display: flex;
        margin-bottom: 25px;
        padding-left: 24px;
        color: $secondaryColor;
        @extend %textVariationTwoone;
        .navbar-Icon {
          font-size: 24px;
        }

        .drop-down {
          display: flex;
          margin-left: 0.8rem;
          font-size: 20px;
          margin-top: 3px;
        }
        span {
          display: inline-block;
          padding-right: 0.6rem;
        }
      }

      .navbar-out {
        width: 100%;
        margin-top: 1rem;
        color: $secondaryColor;
        @extend %textVariationTwoone;
        display: flex;
        top: 45px;
        padding-left: 1.5rem;
        .navbar-Iconr {
          margin-right: 10px;
          font-size: 24px;
        }
      }
    }
  }
}
