@import "../../../assests/stylesheets/common/variables";

.remove-modal {
  z-index: 100000 !important;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  .modal {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    /* Modal Content/Box */
    .modal-content {
      background-color: #fefefe;
      margin: 10% auto;
      padding: 0px;
      border: 1px solid #888;
      left: 497px;
      top: 308px;
      max-width: 446px;
      width: 100%;
      height: 294px;
      border-radius: 24px;
      text-align: center;
      @media screen and (max-width: 530px) {
        margin-top: 180px;
      }
      @media screen and (max-width: 480px) {
        max-width: 340px;
        width: 100%;
        height: 240px;
      }
    }
    .remove-container {
      border-bottom: 2px solid $light-grey;
      padding-bottom: 10px;
      @media screen and (max-width: 480px) {
        padding-bottom: 0px;
      }
    }
    .remove {
      margin-top: 28px;
      color: $dark;
      @extend %textVariationTwoone;
      @media screen and (max-width: 480px) {
        margin-top: 20px;
      }
    }
    .question {
      margin-top: 25px;
      margin-left: 52px;
      color: #595959;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      max-width: 334px;
      width: 100%;
      height: 48px;
      word-wrap: break-word;
      text-align: left;
      @media screen and (max-width: 480px) {
        max-width: 100%;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        width: 70%;
      }
    }
    .deleteParagraph {
      @extend %flex;
      text-align: left;
      margin-right: 125px;
      margin-top: 18px;
      @media screen and (max-width: 480px) {
        margin-top: 30px;
        margin-left: 30px;
      }
    }
    .delete {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 16px;
      color: #000000;
      margin-left: 8px;
    }
    input {
      max-width: 20px;
      width: 100%;
      height: 20px;
      @media screen and (max-width: 320px) {
      }
    }
    .dl__btns {
      display: flex;
      float: right;
      margin-top: 40px;
      margin-right: 37px;
      @media screen and (max-width: 480px) {
        margin-top: 30px;
      }
      .delete-btn {
        width: 84px;
        height: 48px;
        background-color: #ff4f33;
        border: none;
        border-radius: 8px;
        cursor: pointer;
      }
      .delete-text {
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: $white;
      }
      .cancel-btn {
        margin-right: 16px;
      }
    }
  }
}
