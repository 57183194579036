@import "../../../../assests/stylesheets/common/variables";

.main__main {
  margin-top: 30px;
  padding: 0 50px;
  @media screen and (max-width: 768px) {
    padding: 0 24px;
    margin-top: 24px;
  }
  .main-sub {
    .header-container {
      @extend %flex-justify-between;
      h1 {
        @extend %textVariationSix;
      }
    }
    .retailNumber {
      background-color: $primaryColor;
      color: $white;
      padding-left: 12px;
      padding-right: 12px;
      border-radius: 4px;
      width: 26px;
      height: 26px;
    }
    .subHeader-right {
      @extend %flex-justify-between;
      .btn-div {
        @extend %flex;
        @media screen and (max-width: 970px) {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
        .new-btn {
          @extend %newBtn;
        }
        .new-img {
          @extend %btn-image;
        }
        .add {
          margin-right: 16px;
        }
        .filter-img {
          margin-right: 10px;
          font-size: 20px;
          @media screen and (max-width: 480px) {
            font-size: 18px;
          }
        }
      }
    }
    .main-card {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1.6rem;
      margin-top: 40px;
      @media screen and (max-width: 1260px) {
        grid-template-columns: repeat(1, 1fr);
      }
      .summary-card {
        background: $blue-50;
        border-radius: 16px;
        color: $dark;
        height: 270px;
        max-width: 3000px;
        width: 100%;
        @media screen and (max-width: 2500px) {
          max-width: 2500px;
        }
        @media screen and (max-width: 1500px) {
          max-width: 1390px;
        }
        @media screen and (max-width: 1000px) {
          max-width: 620px;
        }
        @media screen and (max-width: 768px) {
          max-width: 715px;
        }
        @media screen and (max-width: 650px) {
          max-width: 600px;
        }
        @media screen and (max-width: 600px) {
          max-width: 700px;
        }
        @media screen and (max-width: 500px) {
          max-width: 365px;
          height: 250px;
        }
        .main-title {
          margin-top: 20px;
          margin-left: 32px;
          @media screen and (max-width: 500px) {
            margin-left: 20px;
          }
          .main-descr {
            color: #002766;
            width: 155px;
            height: 24px;
            @extend %textVariationTwoone;
          }
        }
        .card-dsicrption {
          margin-top: 40px;
        }
        .description {
          @extend %cardDescription;
          .second {
            color: $dark;
            @extend %textVariationTwoone;
          }
        }
      }
      .transaction-cards {
        background: $white;
        border-radius: 16px;
        color: $dark;
        border: 1px solid #d7d7d7;
        height: 270px;
        max-width: 3000px;
        width: 100%;
        left: 591px;
        top: 120px;
        @media screen and (max-width: 2500px) {
          max-width: 2500px;
        }
        @media screen and (max-width: 1500px) {
          max-width: 1390px;
        }
        @media screen and (max-width: 1000px) {
          max-width: 620px;
        }
        @media screen and (max-width: 768px) {
          max-width: 715px;
        }
        @media screen and (max-width: 650px) {
          max-width: 600px;
        }
        @media screen and (max-width: 600px) {
          max-width: 700px;
        }
        @media screen and (max-width: 500px) {
          max-width: 365px;
          height: 250px;
        }
        .description2 {
          @extend %cardDescription2;
          @media screen and (max-width: 500px) {
            margin-left: 20px;
          }
          .second {
            color: $dark;
            @extend %textVariationTwoone;
            @media screen and (max-width: 500px) {
              margin-right: 0;
            }
          }
        }
        .description27 {
          margin-top: 17px;
          @extend %cardDescription2;
          @media screen and (max-width: 500px) {
            margin-left: 20px;
          }
          .second {
            color: $dark;
            @extend %textVariationTwoone;
            @media screen and (max-width: 500px) {
              margin-right: 0;
            }
          }
          .home3-sub {
            margin-right: 30px;
            position: relative;
            @media screen and (max-width: 500px) {
              margin-right: 0px;
            }
            select {
              background: $light-grey;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: $dark;
              border-radius: 8px;
              padding: 0 10px;
              height: 38px;
              cursor: pointer;
              appearance: none;
              border: none;
              width: 110px;
              &:focus {
                outline: none !important;
                border: none;
              }
              option {
                @extend %textVariationTwo;
                padding: 10px 0px;
              }
              @media screen and (max-width: 500px) {
                padding: 0 7px;
                height: 34px;
                width: 90px;
                font-size: 14px;
              }
            }
            .date-down {
              position: absolute;
              display: block;
              color: $dark;
              font-size: 20px;
              right: 0;
              left: 5.2rem;
              top: 9.5px;
              pointer-events: none;
              @media screen and (max-width: 500px) {
                left: 4rem;
              }
            }
          }
        }
      }

      .transaction-title {
        margin-top: 24px;
        margin-left: 32px;
        color: #002766;
        @extend %textVariationThree;
        left: 623px;
        top: 184px;
        @media screen and (max-width: 500px) {
          margin-left: 20px;
        }
      }
      .transaction-summary {
        border-bottom: 1px dashed #d7d7d7;
        padding-bottom: 0px;
        width: 90%;
        margin: 0 auto;
      }
    }
    .subTable {
      .table-header {
        @extend %flex-justify-between;
        margin-top: 73px;
        .header {
          @extend %flex;
          position: relative;
          .active {
            color: #282828;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 16px;
          }
          .green {
            position: absolute;
            width: 40px;
            right: 200px;
            top: 27px;
            border-bottom: 3px solid $green;
            padding-bottom: 2px;
            @media screen and (max-width: 480px) {
              right: 190px;
              width: 35px;
            }
          }
          .notActive {
            color: #767676;
            @extend %textVariationThree;
            margin-left: 40px;
            &:hover {
              color: #8c8c8c;
            }
          }
        }
      }
      .transactionTable {
        .pagination--Details {
          @extend %flex;
          justify-content: space-between;
          padding: 48px 28px 16px 28px;
          border: 1px solid #d7d7d7;
          margin-top: -25px;
          margin-bottom: 36px;
          @media screen and (max-width: 480px) {
            padding: 15px 10px;
            margin-bottom: 30px;
          }
          .items-to-show {
            @extend %flex;
            width: 240px;
            position: relative;
            p {
              color: #595959;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              width: 100%;
              height: 24px;
              line-height: 24px;
              @media screen and (max-width: 500px) {
                display: none;
              }
            }
            select {
              background: $light-grey;
              border-radius: 8px;
              padding: 0 18px;
              height: 40px;
              appearance: none;
              color: $dark;
              border: none;
              margin-bottom: 16px;
              width: 130px;
              height: 48px;
              @media screen and (max-width: 500px) {
                height: 35px;
                width: 50px;
                padding: 0 8px;
                margin-right: 180px;
              }
              @media screen and (max-width: 395px) {
                display: none;
              }
              option {
                @extend %textVariationTwo;
                padding: 10px 5px;
              }
            }
            .business-down {
              position: absolute;
              display: block;
              right: 16px;
              top: 15px;
              color: $dark;
              font-size: 20px;
              pointer-events: none;
              @media screen and (max-width: 500px) {
                right: 190px;
                top: 10px;
                font-size: 18px;
              }
              @media screen and (max-width: 395px) {
                display: none;
              }
            }
          }
          .pagination__container {
            background: $white;
            list-style: none;
            display: flex;
            align-items: center;
          }
          .pagination__container a {
            width: 40px;
            height: 40px;
            background-color: $white;
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            margin-right: 10px;
            color: $grey;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            text-align: center;
            @extend %flex;
            cursor: pointer;
            &:hover {
              background: #f7f7f7;
              color: $grey;
              border: none;
            }
            @media screen and (max-width: 500px) {
              font-size: 12px;
              width: 24px;
              height: 24px;
              margin-right: 4px;
            }
          }
          .pages-p {
            width: 40px;
            height: 40px;
            background-color: $white;
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            margin-right: 10px;
            color: $grey;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            text-align: center;
            @extend %flex;
            cursor: pointer;
            @media screen and (max-width: 500px) {
              font-size: 12px;
              width: 24px;
              height: 24px;
              margin-right: 4px;
            }
          }
          .active a {
            width: 40px;
            height: 40px;
            background-color: $primaryColor;
            border-radius: 4px;
            margin-right: 10px;
            color: $white;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            text-align: center;
            @extend %flex;
            cursor: pointer;
            @media screen and (max-width: 500px) {
              font-size: 12px;
              width: 24px;
              height: 24px;
              margin-right: 4px;
            }
          }
        }
      }
    }
  }
  .no__Data {
    @extend %flex-col;
    margin-top: 50px;
    @media screen and (max-width: 480px) {
      margin-top: 30px;
    }
    h2 {
      @extend %textVariationNoData;
      margin-top: 26px;
    }
    h4 {
      @extend %textVariationTwoone;
      color: $grey;
      margin-top: -10px;
    }
  }

  .home3-sub {
    //margin-right: 30px;
    position: relative;
    @media screen and (max-width: 500px) {
      margin-right: 0px;
    }
    select {
      background: $light-grey;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $dark;
      border-radius: 8px;
      padding: 0 10px;
      height: 38px;
      cursor: pointer;
      appearance: none;
      border: none;
      width: 110px;
      &:focus {
        outline: none !important;
        border: none;
      }
      option {
        @extend %textVariationTwo;
        padding: 10px 0px;
      }
      @media screen and (max-width: 500px) {
        padding: 0 7px;
        height: 34px;
        width: 90px;
        font-size: 14px;
      }
    }
    .date-down {
      position: absolute;
      display: block;
      color: $dark;
      font-size: 20px;
      right: 0;
      left: 5.2rem;
      top: 9.5px;
      pointer-events: none;
      @media screen and (max-width: 500px) {
        left: 4rem;
      }
    }
  }

  .transactionTable {
    .pagination--Details {
      @extend %flex;
      justify-content: space-between;
      padding: 48px 28px 16px 28px;
      border: 1px solid #d7d7d7;
      margin-top: -25px;
      margin-bottom: 36px;
      @media screen and (max-width: 480px) {
        padding: 15px 10px;
        margin-bottom: 30px;
      }
      .items-to-show {
        @extend %flex;
        width: 240px;
        position: relative;
        p {
          color: #595959;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          width: 100%;
          height: 24px;
          line-height: 24px;
          @media screen and (max-width: 500px) {
            display: none;
          }
        }
        select {
          background: $light-grey;
          border-radius: 8px;
          padding: 0 18px;
          height: 40px;
          appearance: none;
          color: $dark;
          border: none;
          margin-bottom: 16px;
          width: 130px;
          height: 48px;
          @media screen and (max-width: 500px) {
            height: 35px;
            width: 50px;
            padding: 0 8px;
            margin-right: 180px;
          }
          @media screen and (max-width: 395px) {
            display: none;
          }
          option {
            @extend %textVariationTwo;
            padding: 10px 5px;
          }
        }
        .business-down {
          position: absolute;
          display: block;
          right: 16px;
          top: 15px;
          color: $dark;
          font-size: 20px;
          pointer-events: none;
          @media screen and (max-width: 500px) {
            right: 190px;
            top: 10px;
            font-size: 18px;
          }
          @media screen and (max-width: 395px) {
            display: none;
          }
        }
      }
      .pagination__container {
        background: $white;
        list-style: none;
        display: flex;
        align-items: center;
      }
      .pagination__container a {
        width: 40px;
        height: 40px;
        background-color: $white;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        margin-right: 10px;
        color: $grey;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-align: center;
        @extend %flex;
        cursor: pointer;
        &:hover {
          background: #f7f7f7;
          color: $grey;
          border: none;
        }
        @media screen and (max-width: 500px) {
          font-size: 12px;
          width: 24px;
          height: 24px;
          margin-right: 4px;
        }
      }
      .pages-p {
        width: 40px;
        height: 40px;
        background-color: $white;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        margin-right: 10px;
        color: $grey;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-align: center;
        @extend %flex;
        cursor: pointer;
        @media screen and (max-width: 500px) {
          font-size: 12px;
          width: 24px;
          height: 24px;
          margin-right: 4px;
        }
      }
      .active a {
        width: 40px;
        height: 40px;
        background-color: $primaryColor;
        border-radius: 4px;
        margin-right: 10px;
        color: $white;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-align: center;
        @extend %flex;
        cursor: pointer;
        @media screen and (max-width: 500px) {
          font-size: 12px;
          width: 24px;
          height: 24px;
          margin-right: 4px;
        }
      }
    }
  }
}
