@import "../../assests/stylesheets/common/variables";

.main--notify {
  margin-top: 30px;
  padding: 0 50px;
  @media screen and (max-width: 768px) {
    padding: 0 24px;
    margin-top: 24px;
  }
  .no-notification {
    font-size: 22px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 5rem;
    color: $dark;
  }
  .notify__content {
    @extend %flex-col;
    aside {
      text-align: center;
      background: $backGroundPrimary;
      border-radius: 16px;
      height: auto;
      padding: 35px;
      @media screen and (max-width: 480px) {
        padding: 0.5px;
        margin-top: 0px;
        background: $white;
        margin-bottom: 0px;
      }
      .notify-form {
        background: $white;
        border-radius: 12px;
        border: 1px solid $dark-11;
        padding: 12px;
        height: 230px;
        width: 300px;

        @media screen and (max-width: 480px) {
          padding: 10px 16px;
        }
        .payment__successful {
          font-size: 16px;
          line-height: 16px;
          font-style: normal;
          font-weight: 400;
          @extend %flex;

          color: $dark-11;
        }
        hr {
          border: 1px solid #b1abab;
          margin: 8px 0;
        }
        .details__part {
          .amount {
            font-size: 22px;
            line-height: 34px;
            font-style: normal;
            font-weight: 600;
            @media screen and (max-width: 480px) {
              margin-top: 22x;
            }
          }

          .payer {
            font-size: 16px;
            line-height: 24px;
            font-style: normal;
            font-weight: 500;
          }

          .qr-type {
            display: grid;
            h5 {
              font-size: 16px;
              line-height: 18px;
              font-style: normal;
              font-weight: 400;
              color: $dark-11;
              .date__string {
                padding-right: 10px;
              }
            }
          }
          .payer__details {
            font-size: 16px;
            line-height: 16px;
            font-style: normal;
            font-weight: 400;
            color: $dark-11;
            text-align: center;
          }
        }
      }
    }
  }
}
