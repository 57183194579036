@import "../../../../assests/stylesheets/common/variables";

.main {
  section {
    @extend %flex;
    form {
      max-width: 630px;
      height: auto;
      width: 100%;
      background: $white;
      display: grid;
      box-shadow: $boxShadow3;
      border-radius: 8px;
      color: $secondaryColor;
      border: none;
      z-index: 1;
      margin-bottom: 10px;
      @extend %flex;
      margin-top: 10px;
      @media screen and (max-width: 480px) {
        padding: 1rem .2rem;
        max-width: 100%;
      }
      .bottom-line {
        width: 100%;
        border-bottom: 1px solid $light-grey;
        padding-bottom: 2px;
      }
      h3 {
        @extend %textVariationFour;
        color: $dark;
        margin-top: 24px;
        @extend %flex;
        @media screen and (max-width: 500px) {
          margin-top: 5px;
        }
      }
      h4 {
        @extend %textVariationThree;
        color: #a4a4a4;
        margin-top: 30px;
        @extend %flex;
        text-align: center;
      }
      h2 {
        font-size: 28px;
        line-height: 120%;
        font-style: normal;
        font-weight: 400;
        color: #1a1a1a;
        @extend %flex;
        text-align: center;
        @media screen and (max-width: 500px) {
          font-size: 22px;
        }
      }
      card {
        max-width: 270px;
        height: auto;
        margin: 0 auto;
        width: 100%;
        background: #f5f9ff;
        align-content: center;
        box-shadow: none;
        padding: 16px 16px;
        div {
          @extend %flex;
          margin-top: 15px;
          .nibss {
            padding-right: 20px;
          }
        }
      }
      h5 {
        font-size: 20px;
        line-height: 26px;
        margin-top: 27px;
        font-style: normal;
        color: $dark-110;
        font-weight: 450;
        @extend %flex;
        @media screen and (max-width: 500px) {
          font-size: 16px;
        }
      }
      .line--wrapper {
        h6 {
          font-size: 16px;
          line-height: 24px;
          font-style: normal;
          margin-top: 5px;
          margin-bottom: 30px;
          font-weight: 500;
          color: $dark-110;
          display: flex;
          flex-direction: row;
        }
        h6:before,
        h6:after {
          content: "";
          flex: 1 1;
          border-bottom: 2px solid $light-grey;
          margin: auto 100px;
        }
        h6:before {
          margin-right: 10px;
        }
        h6:after {
          margin-left: 10px;
        }
      }
      .transferPart_dyns {
        width: auto;
        height: 60px;
        background: #e6efff;
        padding: 0 48.2px;
        @extend %flex;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 7.5rem;
        @media screen and (max-width: 1028px) {
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 7.2rem;
        }
        @media screen and (max-width: 1025px) {
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 6.5rem;
        }
        @media screen and (max-width: 996px) {
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 6rem;
        }
        @media screen and (max-width: 980px) {
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 5.4rem;
        }
        @media screen and (max-width: 963px) {
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 5rem;
        }
        @media screen and (max-width: 950px) {
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 4rem;
        }
        @media screen and (max-width: 920px) {
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 3.5rem;
          padding: 0 35px;
        }
        @media screen and (max-width: 876px) {
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 2.7rem;
        }
        @media screen and (max-width: 858px) {
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 1.5rem;
        }
        @media screen and (max-width: 820px) {
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 1.5rem;
          padding: 0 20px;
        }
        @media screen and (max-width: 780px) {
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 1rem;
          padding: 0 10px;
        }
        @media screen and (max-width: 768px) {
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 8.4rem;
          padding: 0 25px;
        }
        @media screen and (max-width: 679px) {
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 6.4rem;
          padding: 0 25px;
        }
        @media screen and (max-width: 540px) {
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 3rem;
          padding: 0 30px;
        }
        @media screen and (max-width: 480px) {
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 2.5rem;
          padding: 0 15px;
        }
        @media screen and (max-width: 395px) {
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 1rem;
          padding: 0 12px;
        }
        @media screen and (max-width: 375px) {
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 1.7rem;
          padding: 0 10px;
        }
        @media screen and (max-width: 360px) {
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 0.8rem;
          padding: 0 8px;
        }
        .payWith {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          color: $dark;
          display: flex;
        }
        .accNo {
          @extend %textVariationFive;
          color: #2826d9;
        }
        .wemaBank {
          @extend %textVariationTwotwo;
          color: $dark;
        }
      }
      p {
        font-size: 16px;
        width: 100%;
        line-height: 16px;
        font-style: normal;
        font-weight: 400;
        margin-top: 0px;
        text-align: left;
        color: $dark-110;
        @media screen and (max-width: 480px) {
          justify-content: flex-start;
        }
      }
    }
    .time-wrap_dyn {
      width: 60%;
      height: 40px;
      background: #f9f4dc;
      padding: 0 48px;
      margin: 0 auto;
      @extend %flex;
      margin-top: 35px;
      margin-bottom: 20px;
      @media screen and (max-width: 960px) {
        width: 100%;
      }
      @media screen and (max-width: 375px) {
        padding: 0 20px;
      }
      h5 {
        @extend %textVariationOne;
        text-align: left;
        margin-top: 8px;
        color: $grey;
        .warningIcon {
          margin-right: 5px;
          font-size: 20px;
        }
        span {
          padding-left: 1.5px;
        }
      }
    }
  }
}
