@import "../../../../assests/stylesheets/common/variables";

.option__modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  .option--wrap {
    position: absolute;
    max-width: 560px;
    height: auto;
    margin: 7% auto;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
    background: #fff;
    color: #000000;
    display: grid;
    position: relative;
    z-index: 1000;
    border-radius: 16px;
    @media screen and (max-width: 480px) {
      max-width: 340px;
      width: 100%;
    }
    form {
      display: flex;
      flex-direction: column;
      color: #595959;
      .header-part {
        h2 {
          color: $dark;
          @extend %textVariationTwoone;
          margin-top: 28px;
          margin-bottom: 20px;
          @extend %flex;
          position: relative;

          @media screen and (max-width: 500px) {
            margin-top: 20px;
            margin-bottom: 16px;
          }
          .close__modal {
            position: absolute;
            right: 8%;
            color: red;
            cursor: pointer;
          }
        }
      }

      .body-part {
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 10px 45px 45px 45px;
        .horizontal__row {
          background: #F7F7F7;
          border: 1px solid #C4C4C4;
          border-radius: 8px;
          padding: 12px 22px 8px 22px;
          display: flex;
          margin-bottom: 16px;
          cursor: pointer;
          &:hover {
            background: #efebeb;
          }
          .rounded__part {
            background: #D9D9D9;
            border-radius: 59px;
            width: 70px;
            position: relative;
            height: 45px;
            .school__icon {
              position: absolute;
              top: 28%;
              right: 31%;
            }
          }
          .rounded__part2 {
            background: #D9D9D9;
            border-radius: 59px;
            width: 60px;
            position: relative;
            height: 45px;
            .school__icon {
              position: absolute;
              top: 28%;
              right: 31%;
            }
          }
          .rounded__part3 {
            background: #D9D9D9;
            border-radius: 59px;
            width: 45px;
            position: relative;
            height: 45px;
            .school__icon {
              position: absolute;
              top: 28%;
              right: 31%;
            }
            .school__icon3 {
              position: absolute;
              top: 28%;
              right: 34%;
            }
          }
        }
        .descriptn__part {
          display: grid;
          margin-left: 16px;
          .top__descriptn {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            color: #595959;
          }
          .sub__descriptn {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #595959;
          }
        }

        .log__btns {
          display: flex;
          margin-top: 40px;
          margin-left: 182px;
          @media screen and (max-width: 500px) {
            margin-top: 20px;
            margin-left: 90px;
          }

          .logout-btn {
            width: auto;
            display: flex;
            height: 48px;
            background-color: #1a71ff;
            border: none;
            justify-content: center;
            border-radius: 8px;
            padding: 16px;
            left: 1026px;
            top: 32px;
            cursor: pointer;

            @media screen and (max-width: 500px) {
              font-size: 14px;
              font-weight: 500;
              height: 38px;
              padding: 10px;
              top: 12px;
            }
          }

          .export-text {
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            color: $white;

            @media screen and (max-width: 500px) {
              font-size: 14px;
              font-weight: 500;
            }
          }

          .stay-btn {
            visibility: hidden;
            width: auto;
            height: 48px;
            top: 32px;
            left: 912px;
            background-color: $white;
            border: 1px solid #d9d9d9;
            border-radius: 8px;
            padding: 16px;
            margin-right: 16px;
            color: $grey;
            font-size: 16px;
            font-weight: 600;
            line-height: 16px;
            @extend %flex;
            cursor: pointer;

            @media screen and (max-width: 500px) {
              font-size: 14px;
              height: 38px;
              padding: 10px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}