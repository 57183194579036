@import "../../assests/stylesheets/common/variables";

.bank-main {
  margin-top: 30px;
  padding: 0 50px;
  @media screen and (max-width: 768px) {
    padding: 0 24px;
    margin-top: 24px;
  }
  .row {
    display: flex;
    margin: 0 -15px -15px -15px;
    flex-wrap: wrap;
    align-items: center;
    .account-play {
      margin-left: 30rem;
      margin-top: -10rem;
      position: absolute;
      color: $secondaryColor;
      font-size: 20px;
      @media screen and (max-width: 1380px) {
        display: none;
      }
    }
    .column {
      margin-bottom: 15px;
      padding-right: 15px;
      padding-left: 15px;
      flex: 1;
      max-width: 50%;
      flex-basis: 50%;
      @media screen and (max-width: 1200px) {
        max-width: 100%;
        flex-basis: 100%;
        display: flex;
        justify-content: center;
      }

      .account-wrap {
        margin-bottom: -1rem;
        @media screen and (max-width: 768px) {
          margin-top: 2rem;
        }
        h3 {
          color: $dark;
          @extend %textVariationThreeone;
          margin-top: 1rem;
        }
        p {
          color: $grey;
          @extend %textVariationTwo;
          margin-top: 1rem;
          max-width: 500px;
        }
        form {
          max-width: 560px;
          height: auto;
          width: 100%;
          display: grid;
          padding: 32px 88px;
          border-radius: 8px;
          color: $secondaryColor;
          border: none;
          z-index: 1;
          box-shadow: $boxShadow2;
          background: $white;
          margin-top: 28px;
          @media screen and (max-width: 500px) {
            padding: 16px 32px;
          }

          label {
            color: $grey;
            @extend %textVariationOne;
            margin-bottom: 8px;
          }
          .business-option {
            width: 100%;
            position: relative;
            select {
              background: #f7f7f7;
              border-radius: 8px;
              padding: 0 16px;
              height: 56px;
              appearance: none;
              color: $grey;
              border: none;
              margin-bottom: 16px;
              width: 100%;
              &:focus {
                outline: none !important;
                border: 2px solid #1a71ff;
              }
              option {
                @extend %textVariationTwo;
                padding: 10px 5px;
              }
            }
            .bankInput {
              @extend %inputOne;
              margin-bottom: 16px;
            }
            .invalid {
              border: 1.5px solid red;
            }
            .invalid:focus {
              border: 1.5px solid red;
            }
            .business-down {
              position: absolute;
              display: block;
              right: 16px;
              font-size: 20px;
              top: 20px;
              pointer-events: none;
            }
          }
          input {
            @extend %inputOne;
            margin-bottom: 16px;
          }
          .acc-Name {
            width: 100%;
            position: relative;
           // margin-bottom: -40px;
            input {
              width: 100%;
              @extend %inputOne;
              margin-bottom: 16px;
            }
            .spinner_input {
              position: absolute;
              right: 20px;
               top: -55px;
            }
          }
          .bankInput {
            @extend %inputOne;
            margin-bottom: 16px;
          }
          .invalid {
            border: 1.5px solid red;
          }
          .invalid:focus {
            border: 1.5px solid red;
          }
          button {
            @extend %inputTwo;
            @extend %textVariationbtn;
            margin-top: 1.5rem;
            margin-bottom: 24px;
          }
        }
      }
    }
  }
}
