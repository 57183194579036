@import "../../assests/stylesheets/common/variables";

.create__sub {
  margin-top: 30px;
  padding: 0 50px;
  @media screen and (max-width: 768px) {
    padding: 0 24px;
    margin-top: 24px;
  }

  .add--sub {
    font-size: 22px;
    font-weight: 600;
    line-height: 24px;
    color: $dark;
    @media screen and (max-width: 960px) {
      align-items: center;
      justify-content: center;
      width: 100%;
      display: flex;
    }
  }
  section {
    margin-top: 3rem;
    margin-bottom: 2rem;
    form {
      max-width: 700px;
      height: auto;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      background: #fefefe;
      display: grid;
      box-shadow: $boxShadow3;
      padding: 0;
      border-radius: 24px;
      margin-bottom: 20px;
      margin-top: 10px;
      @media screen and (max-width: 480px) {
        margin-top: 30px;
      }
      .add--line--bottom {
        border-bottom: 2px solid $light-grey;
        .add--content {
          margin-top: 20px;
          font-style: normal;
          margin-bottom: 20px;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
        }
      }
      .form--card {
        padding: 27px 64px;
        @media screen and (max-width: 480px) {
          padding: 27px;
        }
        aside {
          font-size: 14px;
          padding-bottom: 12px;
          color: red;
          font-weight: 400;
        }
        .addInput {
          @extend %inputOne;
          margin-bottom: 16px;
        }
        .invalid {
          border: 1.5px solid red;
        }
        .invalid:focus {
          border: 1.5px solid red;
        }
        label {
          color: $grey;
          @extend %textVariationOne;
          margin-bottom: 8px;
        }
        input {
          @extend %inputOne;
          margin-bottom: 16px;
          width: 100%;
          cursor: pointer;
        }
        .label {
          color: $grey;
          @extend %textVariationOne;
          margin-bottom: 8px;
        }
        .input {
          width: 100%;
          position: relative;
          input {
            @extend %inputOne;
            margin-bottom: 24px;
            width: 100%;
            cursor: pointer;
          }
          .invalid {
            border: 1.5px solid red;
          }
          .invalid:focus {
            border: 1.5px solid red;
          }
          .input-eye {
            position: absolute;
            display: block;
            right: 16px;
            top: 20px;
            cursor: pointer;
            font-size: 20px;
            color: $grey;
          }
        }
        select {
          background: #f7f7f7;
          border-radius: 8px;
          padding: 0 16px;
          height: 56px;
          appearance: none;
          color: $grey;
          border: none;
          margin-bottom: 16px;
          width: 100%;
          &:focus {
            outline: none !important;
            border: 1.5px solid #1a71ff;
          }
          option {
            @extend %textVariationTwo;
            padding: 10px 5px;
          }
        }
        span {
          display: flex;
          gap: 20px;
          width: 100%;
          @media screen and (max-width: 1080px) {
            display: grid;
            gap: 0px;
          }
          .left-input {
            display: grid;
            width: 276px;
            @media screen and (max-width: 1080px) {
              width: 100%;
            }
          }
          .right-input {
            display: grid;
            width: 276px;
            @media screen and (max-width: 1080px) {
              width: 100%;
            }
          }

          .rig-input {
            display: grid;
            width: 276px;
            position: absolute;
            left: 1041px;
            top: 292px;
            @media screen and (max-width: 1080px) {
              width: 100%;
            }
          }
          .addropDown {
            position: relative;
            .add--down {
              position: absolute;
              display: block;
              right: 16px;
              font-size: 25px;
              top: 43px;
              pointer-events: none;
            }
          }
        }
        .btn__bottom {
          display: flex;
          justify-content: space-between;
          .sms-section {
            display: flex;
            position: relative;
            justify-content: flex-start;
            @media screen and (max-width: 480px) {
              display: none;
            }
            .switch {
              height: 25px;
              position: absolute;
              right: 45%;
              @media screen and (max-width: 480px) {
                top: 20%;
              }
            }
            .sms-alert {
              font-size: 15px;
              line-height: 24px;
              font-weight: 500;
              padding-left: 7px;
              color: $grey;
              padding-left: 35px;
            }
          }
          button {
            background-color: $primaryColor;
            border: none;
            border-radius: 8px;
            height: 56px;
            margin-top: 25px;
            padding: 0px 20px;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            color: $white;
            cursor: pointer;
            @media screen and (max-width: 960px) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
