@import "../../assests/stylesheets/common/variables";

.settlement__main {
  margin-top: 30px;
  padding: 0 50px;
  @media screen and (max-width: 768px) {
    padding: 0 24px;
    margin-top: 24px;
  }
  .main-settle {
    .header-container {
      @extend %flex-justify-between;
      @media screen and (max-width: 500px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      h1 {
        @extend %textVariationSix;
      }
    }
    .subHeader-right {
      @extend %flex-justify-between;
    }
    .settlement-card {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1.6rem;
      margin-top: 40px;
      @media screen and (max-width: 1150px) {
        grid-template-columns: repeat(1, 1fr);
      }
      .card {
        background: $blue-50;
        border-radius: 16px;
        color: $dark;
        height: 254px;
        max-width: 3000px;
        width: 100%;
        @media screen and (max-width: 2500px) {
          max-width: 2500px;
        }
        @media screen and (max-width: 1500px) {
          max-width: 1390px;
        }
        @media screen and (max-width: 1000px) {
          max-width: 620px;
        }
        @media screen and (max-width: 768px) {
          max-width: 715px;
        }
        @media screen and (max-width: 650px) {
          max-width: 600px;
        }
        @media screen and (max-width: 600px) {
          max-width: 700px;
          height: 235px;
        }
        @media screen and (max-width: 414px) {
          max-width: 365px;
        }
        .main-title {
          margin-top: 20px;
          margin-left: 32px;
          @media screen and (max-width: 500px) {
            margin-left: 20px;
          }
          .main-descr {
            color: #002766;
            width: 155px;
            @extend %textVariationTwoone;
          }
        }
        .card-dsicrption {
          margin-top: 30px;
        }
        .description {
          display: flex;
          justify-content: space-between;
          margin-top: 0;
          margin-left: 32px;
          @media screen and (max-width: 500px) {
            margin-left: 20px;
          }
          p {
            @extend %cardTextVariation;
            color: $dark;
          }
          .second {
            margin-right: 32px;
            @extend %textVariationTwo;
            color: $dark;
            @media screen and (max-width: 500px) {
              margin-right: 20px;
            }
          }
          .snd {
            margin-right: 32px;
            @extend %textVariationTwoone;
            color: $dark;
            @media screen and (max-width: 500px) {
              margin-right: 20px;
            }
          }
        }
      }
      .transact-cards {
        background: $white;
        border-radius: 16px;
        color: $dark;
        border: 1px solid #d7d7d7;
        height: 254px;
        max-width: 3000px;
        width: 100%;
        left: 591px;
        top: 120px;
        @media screen and (max-width: 2500px) {
          max-width: 2500px;
        }
        @media screen and (max-width: 1500px) {
          max-width: 1390px;
        }
        @media screen and (max-width: 1000px) {
          max-width: 620px;
        }
        @media screen and (max-width: 768px) {
          max-width: 715px;
        }
        @media screen and (max-width: 650px) {
          max-width: 600px;
        }
        @media screen and (max-width: 600px) {
          max-width: 700px;
          height: 235px;
        }
        @media screen and (max-width: 414px) {
          max-width: 365px;
        }
        .description2 {
          display: flex;
          justify-content: space-between;
          margin-left: 32px;
          @media screen and (max-width: 500px) {
            margin-left: 20px;
          }
          p {
            @extend %cardTextVariation;
            color: $dark-110;
          }
          .second {
            margin-right: 32px;
            color: $dark;
            @extend %textVariationTwoone;
            @media screen and (max-width: 500px) {
              margin-right: 20px;
            }
          }
        }
      }
      .transaction-title {
        margin-top: 20px;
        margin-left: -10px;
        color: #002766;
        @extend %textVariationThree;
        left: 623px;
        top: 184px;
        @media screen and (max-width: 500px) {
          margin-left: 0px;
        }
      }
      .transaction-summary {
        border-bottom: 1px solid #d7d7d7;
        padding-bottom: 0px;
        width: 88%;
        margin: 0 auto 15px auto;
      }
    }
    .subTable {
      .table-header {
        @extend %flex-justify-between;
        margin-top: 73px;
        .header {
          @extend %flex;
          .active {
            color: #282828;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 16px;
            height: 16px;
          }
          .notActive {
            color: #767676;
            @extend %textVariationThree;
            margin-left: 40px;
          }
        }
        .export-btn {
          width: 84px;
          height: 48px;
          background-color: $primaryColor;
          border: none;
          border-radius: 8px;
          left: 1026px;
          top: 32px;
          cursor: pointer;
        }
        .export-text {
          @extend %textVariationSeven;
          color: $white;
        }
      }
      .items {
        @extend %inputFive;
      }
      .subBorder {
        border: 1px solid #d7d7d7;
        margin-top: -25px;
        width: 100%;
        max-width: 5500px;
      }
      .sub-pagination {
        @extend %flex;
        margin-top: 20px;
        @media screen and (max-width: 394px) {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
