@import "../../assests/stylesheets/common/variables";

.header-top {
  margin-left: 284px;
  position: sticky;
  background: $white;
  z-index: 99;
  top: 0;
  @media screen and (max-width: 1000px) {
    margin-left: 100px;
  }
  @media screen and (max-width: 768px) {
    margin-left: 0px;
  }
  .top-bar {
    img {
      cursor: pointer;
    }
    @extend %flex;
    justify-content: space-between;
    padding: 5px 50px;
    border-bottom: 1px solid #d9d9d9;
    @media screen and (max-width: 768px) {
      padding: 10px 24px;
    }
    h2 {
      color: #000000;
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      margin-top: 10px;
      @media screen and (max-width: 768px) {
        margin-top: 0px;
      }
      .userName {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
      }
    }
    .topbar {
      display: flex;
      justify-content: center;
      align-items: center;
      .top-notify {
        padding-right: 1rem;
        position: relative;
        .bell {
          font-size: 24px;
          color: $dark;
        }
        .dot {
          position: absolute;
          display: block;
          right: 13px;
          top: -2px;
          font-size: 15px;
          color: $red;
          pointer-events: none;
        }
      }

      .top-drp {
        justify-content: center;
        align-items: center;
        .top-drop {
          padding-left: 0;
          font-size: 22px;
        }
      }
    }
  }
}

.top-menu {
  margin-top: 0.5rem !important;
  border-radius: 8px !important;
  height: auto !important;
  padding: 20px 35px !important;
  box-shadow: $boxShadow2 !important;
  .top-items {
    color: $dark;
    @extend %textVariationMino;
    margin-bottom: 5px;
  }
  .top-items:hover {
    background: none !important;
  }
  .top-item {
    color: $dark;
    @extend %textVariationSevenone;
    margin-bottom: 24px;
  }
  .top-item:hover {
    background: none !important;
  }
  .logout-hover:hover {
    background: none !important;
  }
  .divider {
    background: #efefef;
    margin-bottom: 24px !important;
    margin-top: 20px !important;
  }
  .top-settings {
    color: $dark;
    @extend %textVariationSevenone;
    margin-bottom: 20px;
  }
  .top-settings:hover {
    color: $primaryColor;
    background: none !important;
  }

  .top-support {
    color: $dark;
    @extend %textVariationSevenone;
  }
  .top-support:hover {
    color: $primaryColor;
    background: none !important;
  }
  section {
    align-items: center;
    display: flex;
    .top-Iconr {
      font-size: 24px;
      transform: rotate(90deg);
      color: $red;
    }
    span {
      color: $dark;
      padding-left: 16px;
      @extend %textVariationSevenone;
    }
    span:hover {
      background: none !important;
    }
  }
  section:hover {
    background: none !important;
  }
}
