@import "../../../../assests/stylesheets/common/variables";

.bank-settings {
  margin-top: 25px;
  @media screen and (max-width: 768px) {
    padding: 0 24px;
    margin-top: 24px;
  }
  section {
    width: 100%;
    border-bottom: 1.5px solid $light-grey;
    padding-bottom: 25px;
    @media screen and (max-width: 1200px) {
      overflow-x: scroll;
      white-space: nowrap;
      overflow: auto;
    }
    .personal-info {
      margin-left: 50px;
      @media screen and (max-width: 500px) {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 20px;
      }
    }
    .activee {
      background: #efefef;
      border-radius: 8px;
      padding: 12px;
      margin-right: 40px;
      @media screen and (max-width: 500px) {
        background: none;
        border-radius: none;
        margin-left: -12px;
        margin-bottom: 20px;
        color: #b4a8a8;
      }
    }
    span {
      color: #2d2d2d;
      font-size: 16px;
      line-height: 16px;
      font-weight: 600;
      padding-right: 55px;
      display: inline-block;
      @media screen and (max-width: 500px) {
        padding-right: 30px;
      }
    }
  }
  #sect::-webkit-scrollbar {
    width: thin;
  }
  .intro-txt {
    display: grid;
    padding-left: 50px;
    margin-bottom: 32px;
    @media screen and (max-width: 500px) {
      padding-left: 0;
    }
    h2 {
      color: $dark;
      @extend %textVariationFour;
      margin-top: 35px;
    }
    h4 {
      color: $grey-2;
      @extend %textVariationTwo;
      margin-top: -5px;
    }
  }
  .line-border {
    border: 1px solid #d7d7d7;
    border-radius: 4px;
    height: auto;
    padding: 40px 32px;
    margin: 0 50px 20px 50px;
    @media screen and (max-width: 500px) {
      height: auto;
      margin: 0 0 10px 0;
    }
    .row {
      display: flex;
      margin: 0 -15px -15px -15px;
      flex-wrap: wrap;
      align-items: center;
      .column {
        margin-bottom: 15px;
        padding-right: 15px;
        padding-left: 15px;
        flex: 1;
        max-width: 50%;
        flex-basis: 50%;
        @media screen and (max-width: 1200px) {
          max-width: 100%;
          flex-basis: 100%;
          display: flex;
          justify-content: center;
        }
        .image-update {
          @extend %flexx;
          .board-wrap {
            height: auto;
            width: 100%;
            max-width: 354px;
            display: grid;
            background: $white;
            border: 1px solid #d7d7d7;
            padding: 24px;
            border-radius: 24px;
            @media screen and (max-width: 1300px) {
              margin-top: 30px;
            }
            @media screen and (max-width: 500px) {
              padding: 24px 16px;
            }
            .btm__line {
              width: 100%;
              border-bottom: 1.5px solid $light-grey;
              margin-bottom: 20px;
              p {
                color: $dark;
                @extend %textVariationTwoone;
                padding: 0 32px;
              }
            }
          }
        }
        form {
          height: auto;
          max-width: 552px;
          display: grid;
          color: $secondaryColor;
          border: none;
          z-index: 1;
          background: $white;
          @media screen and (max-width: 1300px) {
            width: 100%;
            max-width: 100%;
          }
          label {
            color: $grey;
            @extend %textVariationOne;
            margin-bottom: 8px;
          }
          .bankInput {
            @extend %inputOne;
            margin-bottom: 16px;
          }
          .invalid {
            border: 1.5px solid red;
          }
          .invalid:focus {
            border: 1.5px solid red;
          }
          .bankInput {
            width: 100%;
            @extend %inputOne;
          }
          .invalid {
            border: 1.5px solid red;
          }
          .invalid:focus {
            border: 1.5px solid red;
          }
          input {
            background: #f7f7f7;
            border-radius: 8px;
            border: none;
            padding: 0 16px;
            height: 56px;
          }
          .input {
            width: 100%;
            position: relative;
            .loginInput {
              width: 100%;
              background: #f7f7f7;
              border-radius: 8px;
              border: none;
              padding: 0 16px;
              height: 56px;
            }
            .invalid {
              border: 1.5px solid red;
            }
            .invalid:focus {
              border: 1.5px solid red;
            }
            .input-eye {
              position: absolute;
              display: block;
              right: 16px;

              top: 17px;
              cursor: pointer;
              font-size: 20px;
              color: $grey;
            }
          }
          input {
            background: #f7f7f7;
            border-radius: 8px;
            border: none;
            padding: 0 16px;
            height: 56px;
            margin-bottom: 16px;
          }

          .name_row {
            display: flex;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 1.2rem;
            @media screen and (max-width: 960px) {
              display: grid;
              grid-template-columns: auto;
              grid-gap: 0rem;
            }
            .first-row {
              width: 100%;
              display: grid;
            }
            .biz_option {
              width: 100%;
              display: grid;
              position: relative;
              select {
                background: #f7f7f7;
                border-radius: 8px;
                padding: 0 16px;
                height: 56px;
                appearance: none;
                color: $grey;
                border: none;
                margin-bottom: 16px;
                width: 100%;
                &:focus {
                  outline: none !important;
                  border: 2px solid #1a71ff;
                }
                option {
                  @extend %textVariationTwo;
                  padding: 10px 5px;
                }
              }
              .biz_down {
                position: absolute;
                display: block;
                right: 16px;
                font-size: 20px;
                top: 45px;
                pointer-events: none;
              }
            }
          }

          .business-option {
            width: 264px;
            position: relative;
            @media screen and (max-width: 1200px) {
              width: 100%;
            }
            select {
              background: #f7f7f7;
              border-radius: 8px;
              padding: 0 16px;
              height: 56px;
              appearance: none;
              color: $grey;
              border: none;
              margin-bottom: 16px;
              width: 100%;
              &:focus {
                outline: none !important;
                border: 2px solid #1a71ff;
              }
              option {
                @extend %textVariationTwo;
                padding: 10px 5px;
              }
            }
            .business-down {
              position: absolute;
              display: block;
              right: 16px;
              font-size: 20px;
              top: 20px;
              pointer-events: none;
            }
            .spin--input {
              position: absolute;
              right: 20px;
               top: 18px;
            }
          }
         
          .acc-Name {
            width: 100%;
            position: relative;
            input {
              width: 100%;
              @extend %inputOne;
              margin-bottom: 16px;
            }
            .spinner_input {
              position: absolute;
              right: 20px;
               top: -55px;
            }
          } 
          
          .profile-personal-Input {
            @extend %inputOne;
            margin-bottom: 16px;
          }
          .btn__edit {
            @extend %inputEdit;
            @extend %textVariationTwotwo;
            border: 1px solid #d9d9d9;
            margin-top: 1.5rem;
            margin-bottom: 24px;
            @media screen and (max-width: 1300px) {
              max-width: 100%;
            }
            span {
              padding-right: 10px;
            }
          }
          .btn--submit {
            @extend %inputTwo;
            @extend %textVariationbtn;
            margin-bottom: 16px;
            cursor: pointer;
            .sign {
              color: $white;
            }
          }
        }
      }
    }
  }
}
