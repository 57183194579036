@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;700&display=swap');
@import "./common/variables";
@import "./common/mixins";

#font_change {
  font-family: "Roboto-Medium", sans-serif;
  font-weight: 550;
}

html,
body {
  font-family: "Heebo", sans-serif;
}

body,
html {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
  list-style: none;
  height: 100%;
  overflow-x: hidden;
  user-select: text;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-decoration: none;
  user-select: text;
}

button:disabled {
  cursor: not-allowed;
}

