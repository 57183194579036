@import "../../assests/stylesheets/common/variables";

.recover {
  min-height: 100vh;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  overflow: hidden;
  background: $backGroundPrimary;
  &-wrap {
    @extend %flex;
    flex-direction: column;
    padding: 0 24px;
    .img {
      @extend %flex;
      width: 100%;
      padding: 100px 0 40px 0;
      @media screen and (max-width: 480px) {
        padding: 98px 0 40px 0;
      }
    }
    .verify-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      form {
        max-width: 448px;
        height: auto;
        width: 100%;
        display: grid;
        padding: 16px 32px;
        border-radius: 8px;
        color: $secondaryColor;
        border: none;
        z-index: 1;
        box-shadow: $boxShadow;
        background: $white;
        margin-bottom: 28px;
        section {
          font-size: 14px;
          padding-bottom: 8px;
          color: red;
          font-weight: 400;
        }
        .timer {
          padding: 0 0 0 5px;
        }
        .img {
          @extend %flex;
          width: 10%;
          padding: 32px 0;
          margin-left: 10.5rem;
          @media screen and (max-width: 480px) {
            margin-left: 8rem;
          }
          @media screen and (max-width: 375px) {
            margin-left: 6.8rem;
          }
        }
        h2 {
          color: $dark;
          margin-top: 40px;
          margin-bottom: 8px;
          font-size: 22px;
          font-weight: 600;
          line-height: 24px;
          @extend %flex;
        }
        h4 {
          @extend %flex;
          @extend %textVariationOne;
          color: $grey;
          margin-bottom: 32px;
          max-width: 80%;
          margin-left: auto;
          margin-right: auto;
          text-align: center;
        }
        label {
          color: $grey;
          @extend %textVariationOne;
          margin-bottom: 8px;
        }
        .input {
          width: 100%;
          position: relative;
          input {
            @extend %inputOne;
            margin-bottom: 16px;
            width: 100%;
            color: $grey;
            cursor: pointer;
          }
          .invalid {
            border: 1.5px solid red;
          }
          .invalid:focus {
            border: 1.5px solid red;
          }
          .input-eye {
            position: absolute;
            display: block;
            right: 16px;
            top: 14px;
            cursor: pointer;
            font-size: 20px;
            color: $grey;
          }
        }
        .registerInput {
          @extend %inputOne;
          margin-bottom: 16px;
        }
        .invalid {
          border: 1.5px solid red;
        }
        .invalid:focus {
          border: 1.5px solid red;
        }

        p {
          @extend %flex;
          @extend %textVariationOne;
          color: $grey;
          margin-bottom: 32px;
        }
        button {
          @extend %inputTwo;
          @extend %textVariationbtn;
          margin-bottom: 32px;
          margin-top: 32px;
        }
      }
      .verify-create {
        @extend %flex;
        @extend %inputThree;
        max-width: 310px;
        margin-left: 3.5rem;
        height: 48px;
        margin-bottom: 2rem;
        display: grid;
        @media screen and (max-width: 480px) {
          margin-left: 1.5rem;
          padding: 16px;
        }
        @media screen and (max-width: 375px) {
          margin-left: 0.5rem;
        }
        p {
          color: $grey;
          span {
            color: $primaryColor;
            padding: 0 0 0 5px;
          }
        }
      }
    }
  }
}
