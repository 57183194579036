@import "../../assests/stylesheets/common/variables";

.sidebar {
  width: 284px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background: #f4f8ff;
  padding: 15px 20px;
  @media screen and (max-width: 768px) {
    display: none;
  }
  &-brand {
    padding: 0 0 5.1rem 2rem;
  }
  .merchant {
    background-color: #ccdfff;
    padding: 0.6rem 0;
    border-radius: 8px;
    display: flex;
    width: 100%;
    display: flex;
    align-items: center;
    @extend %textVariationTwotwo;
    color: $dark;
    cursor: context-menu;
    margin-bottom: 1rem;
    padding-left: 2rem;
    gap: 14px;
    .span {
      display: gird;
      .userName {
        margin-top: -0.5px;
        @extend %textVariationTwotwo;
       color: $dark;
      }
      .userRole {
        @extend %textVariationOne;
        color: $grey;
       margin-top: -19.4px;
       margin-bottom: -0.5px;
       text-align: center;
      }
    }
  }

  &-menu {
    color: $secondaryColor;
    font-size: 16px;
    line-height: 24px;
    font-style: normal;
    font-weight: 500;
    .menu {
      width: 100%;
      width: 100%;
      display: flex;
      margin-top: 2.6rem;
      padding-left: 2rem;
      margin-bottom: 1rem;
    }
    span {
      display: inline-block;
      padding-right: 0.6rem;
    }
    .payment-collection {
      width: 100%;
      margin-bottom: 0.3rem;
      padding-left: 2rem;
      list-style: none;
      cursor: pointer;
      position: relative;
      padding-top: 0.6rem;
      padding-bottom: 0.6rem;

      .sidebar-Icon {
        font-size: 24px;
        align-self: center;
      }

      div {
        display: flex;
        span.drop-down {
          align-self: center;
          font-size: 20px;
        }
      }
      .payment-dropdown {
        position: absolute;
        margin-top: 15px;
        list-style: none;
        .dropdown-item {
          padding: 10px 35px;

          &:hover {
            background-color: #ccdfff;
            align-self: center;
            color: #002766;
            border-radius: 8px;
          }
        }

        li.active {
          background-color: #1a71ff;
          align-self: center;
          color: #ffffff;
          border-radius: 8px;

          :hover {
            background-color: #1a71ff;
            align-self: center;
            color: #ffffff;
            border-radius: 8px;
          }
        }
      }
    }
    li.list-items {
      display: flex;
      list-style: none;
      padding-left: 2rem;
      padding-top: 0.6rem;
      padding-bottom: 0.4rem;

      &:hover {
        background-color: #ccdfff;
        align-self: center;
        padding-left: 2rem;
        color: #002766;
        border-radius: 8px;
        transition: ease-in-out;
      }

      .sidebar-Icon {
        font-size: 24px;
      }
      .drop-down {
        display: flex;
        margin-left: 0.8rem;
        font-size: 20px;
        margin-top: 3px;
      }
    }

    li.active {
      background-color: #1a71ff;
      align-self: center;
      padding-left: 2rem;
      color: #ffffff;
      border-radius: 8px;
    }
    .sign-out {
      width: 100%;
      display: flex;
      position: absolute;
      bottom: 20px;
      padding-left: 2rem;
      cursor: pointer;
      .sidebar-Icon {
        font-size: 24px;
      }
      .sidebar-Iconr {
        font-size: 24px;
      }
    }
  }
}
