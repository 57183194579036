@import "../../../assests/stylesheets/common/variables";

.logout__modal {
  z-index: 100000 !important;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  .logout-modal {
    z-index: 100000 !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    .logout-content {
      background-color: #fefefe;
      margin: 10% auto;
      padding: 0px;
      z-index: 100000 !important;
      border: 1px solid #888;
      left: 497px;
      top: 308px;
      max-width: 446px;
      width: 100%;
      height: 256px;
      border-radius: 24px;
      text-align: center;
      @media screen and (max-width: 530px) {
        margin-top: 100px;
      }
      @media screen and (max-width: 500px) {
        max-width: 340px;
        width: 100%;
        height: 220px;
      }
    }
    .logout-container {
      border-bottom: 2px solid $light-grey;
      padding-bottom: 2px;
    }

    .logout {
      @extend %textVariationThree;
      margin-top: 20px;
    }
    .modalDropDown {
      max-width: 334px;
      font-weight: 400;
      font-size: 16px;
      font-style: normal;
      justify-content: center;
      line-height: 24px;
      width: 100%;
      position: relative;
      margin-top: 32px;
      margin-left: 56px;
      @media screen and (max-width: 480px) {
        margin-left: 0px;
        max-width: 100%;
      }
    }
    .log__btns {
      display: flex;
      float: right;
      margin-top: 56px;
      margin-right: 50px;
      @media screen and (max-width: 500px) {
        margin-top: 30px;
      }
      .logout-btn {
        width: 91px;
        display: flex;
        height: 48px;
        background-color: red;
        border: none;
        justify-content: center;
        border-radius: 8px;
        padding: 16px;
        left: 1026px;
        top: 32px;
        cursor: pointer;
        @media screen and (max-width: 500px) {
          width: 68px;
          font-size: 14px;
          font-weight: 500;
          height: 34px;
          padding: 8.5px 8px;
          top: 12px;
        }
      }

      .export-text {
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: $white;
        @media screen and (max-width: 500px) {
          font-size: 14px;
          font-weight: 500;
        }
      }
      .stay-btn {
        width: 67px;
        height: 48px;
        top: 32px;
        left: 912px;
        background-color: $white;
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        padding: 16px;
        margin-right: 16px;
        color: $grey;
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        @extend %flex;
        cursor: pointer;
        @media screen and (max-width: 500px) {
          font-size: 14px;
          font-weight: 500;
          height: 34px;
          padding: 16px 8px;
          width: 50px;
        }
      }
    }
  }
}
