@import "../../assests/stylesheets/common/variables";

.select {
  min-height: 100vh;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  overflow: hidden;
  background: $backGroundPrimary;
  .select-wrap {
    @extend %flex;
    flex-direction: column;
    padding: 0 24px;
    .img {
      @extend %flex;
      width: 100%;
      padding: 100px 0 40px 0;
      @media screen and (max-width: 480px) {
      padding: 60px 0 40px 0;
      }
    }
    .select-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      form {
        max-width: 448px;
        height: auto;
        width: 100%;
        display: grid;
        padding: 16px 32px;
        border-radius: 8px;
        color: $secondaryColor;
        border: none;
        z-index: 1;
        box-shadow: $boxShadow;
        background: $white;
        margin-bottom: 28px;
        h4 {
          color: #002766;
          margin-top: 10px;
          margin-bottom: 35px;
          font-size: 22px;
          font-weight: 600;
          line-height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 60px;
          @media screen and (max-width: 480px) {
          padding: 0 20px;
          }
        }
        section {
          font-size: 14px;
          padding-bottom: 8px;
          color: red;
          font-weight: 400;
        }
        .select-option {
          width: 100%;
          position: relative;
          select {
            background: #f7f7f7;
            border-radius: 8px;
            padding: 0 16px;
            height: 56px;
            appearance: none;
            color: $grey;
            border: none;
            margin-bottom: 16px;
            width: 100%;
            &:focus {
              outline: none !important;
              border: 1.5px solid #1a71ff;
            }
            option {
              @extend %textVariationTwo;
              padding: 10px 5px;
            }
          }
          
          .select-down1 {
            position: absolute;
            display: block;
            right: 16px;
            font-size: 20px;
            top: 20px;
            pointer-events: none;
          }
        }
        button {
          @extend %inputTwo;
          @extend %textVariationbtn;
          margin-top: 24px;
          margin-bottom: 16px;
          cursor: pointer;
        }
      }
    }
  }
}
