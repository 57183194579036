@import "../../../../assests/stylesheets/common/variables";

.main__paymentstandM {
  margin-top: 25px;
  @media screen and (max-width: 768px) {
    padding: 0 24px;
    margin-top: 24px;
  }
  .sections {
    width: 100%;
    border-bottom: 1.5px solid $light-grey;
    padding-bottom: 25px;
    @media screen and (max-width: 1200px) {
      overflow-x: scroll;
      white-space: nowrap;
      overflow: auto;
    }
    .personal-info {
      margin-left: 50px;
      @media screen and (max-width: 500px) {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 20px;
      }
    }
    .active-stand {
      background: #efefef;
      border-radius: 8px;
      padding: 12px;
      margin-right: 40px;
      @media screen and (max-width: 500px) {
        background: none;
        border-radius: none;
        height: 0px;
        margin-left: -12px;
        margin-bottom: 20px;
        color: #b4a8a8;
      }
    }
    span {
      color: #2d2d2d;
      font-size: 16px;
      line-height: 16px;
      font-weight: 600;
      padding-right: 55px;
      display: inline-block;
      @media screen and (max-width: 500px) {
        padding-right: 30px;
      }
    }
  }
  #sect::-webkit-scrollbar {
    width: thin;
  }
  .intro-txt {
    display: grid;
    padding-left: 50px;
    margin-bottom: 32px;
    @media screen and (max-width: 500px) {
      padding-left: 0;
    }
    h2 {
      color: $dark;
      @extend %textVariationFour;
      margin-top: 35px;
    }
    h4 {
      color: $grey-2;
      @extend %textVariationTwo;
      margin-top: -5px;
    }
  }
  .line-borderM {
    border: 1px solid #d7d7d7;
    border-radius: 4px;
    height: auto;
    padding: 40px 32px;
    margin: 623px 50px 20px 50px;
    @media screen and (max-width: 500px) {
      height: auto;
      margin: 1105px 0 10px 0;
    }
    .payment-stand {
      @extend %flex-justify-between;
      .header-left {
        @extend %flex;
        .stand {
          font-size: 22px;
          font-weight: 600;
          line-height: 24px;
          color: $dark;
          @media screen and (max-width: 960px) {
            align-items: center;
            justify-content: center;
            width: 100%;
            display: flex;
          }
        }
        #checkbox {
          width: 20px;
        }
        .inputs {
          margin-left: 35.33px;
        }
        span {
          @extend %textVariationTwoone;
          margin-left: 8px;
          margin-right: 16px;
        }
      }
      .icons {
        @extend %flex;
      }
      .filter-btn {
        width: auto;
        height: 48px;
        top: 32px;
        left: 934px;
        background-color: $white;
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        padding: 16px;
        margin-right: 16px;
        color: $grey;
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        text-align: center;
        @extend %flex;
        cursor: pointer;
        @media screen and (max-width: 960px) {
          display: none;
        }
        .save {
          margin-left: 10px;
          color: $primaryColor;
          @extend %textVariationTwotwo;
        }
      }
    }
    section {
      @media screen and (max-width: 500px) {
        margin-top: 10px;
        width: auto;
      }
      @extend %flex;
      form {
        max-width: 630px;
        height: auto;
        width: 100%;
        background: $white;
        padding: 0 30px;
        box-shadow: $boxShadow3;
        border-radius: 8px;
        color: $secondaryColor;
        border: none;
        z-index: 1;
        margin-bottom: 10px;
        margin-top: 10px;
        @media screen and (max-width: 480px) {
          padding: 0 10px;
          width: auto;
        }
        .bottom-line {
          width: 100%;
          border-bottom: 1px solid $light-grey;
          padding-bottom: 2px;
        }
        h3 {
          @extend %textVariationFour;
          color: $dark;
          margin-top: 24px;
          @extend %flex;
          @media screen and (max-width: 500px) {
            margin-top: 5px;
          }
        }
        h6 {
          @extend %textVariationThree;
          margin-top: 5px;
          margin-bottom: 10px;
          color: $dark-110;
          @extend %flex;
        }
        card {
          max-width: 270px;
          height: auto;
          margin: 30px auto 0 auto;
          width: 100%;
          display: grid;
          background: #f5f9ff;
          align-content: center;
          box-shadow: none;
          padding: 16px 16px;
          div {
            @extend %flex;
            margin-top: 15px;

            .nibss {
              padding-right: 20px;
            }
          }
        }
        h5 {
          font-size: 20px;
          line-height: 26px;
          margin-top: 27px;
          font-style: normal;
          color: $dark-110;
          font-weight: 450;
          @extend %flex;
          @media screen and (max-width: 500px) {
            font-size: 16px;
          }
        }
        .line--wrapper {
          h6 {
            font-size: 16px;
            line-height: 24px;
            font-style: normal;
            margin-top: 5px;
            margin-bottom: 30px;
            font-weight: 500;
            color: $dark-110;
            display: flex;
            flex-direction: row;
          }
          h6:before,
          h6:after {
            content: "";
            flex: 1 1;
            border-bottom: 2px solid $light-grey;
            margin: auto 100px;
          }
          h6:before {
            margin-right: 10px;
          }
          h6:after {
            margin-left: 10px;
          }
        }
        .line__wrapper2 {
          h6 {
            font-size: 16px;
            line-height: 24px;
            font-style: normal;
            margin-top: 5px;
            margin-bottom: 30px;
            font-weight: 500;
            color: $dark-110;
            display: flex;
            flex-direction: row;
          }
          h6:before,
          h6:after {
            content: "";
            flex: 1 1;
            border-bottom: 1px solid #d7d7d7;
            margin: auto 50px;
          }
          h6:before {
            margin-right: 10px;
          }
          h6:after {
            margin-left: 10px;
          }
        }
        .transferPart {
          max-width: auto;
          height: auto;
          background: $lightPrimary;
          margin-bottom: 35px;
          display: grid;
          padding: 30px;
          border-radius: 8px;
          .gtPart {
            @extend %flex;
            justify-content: space-between;
            .payWith {
              @extend %textVariationSevenone;
              color: $dark;
              text-align: left;
              margin-bottom: 24px;
            }
            .accNo {
              @extend %textVariationFive;
              color: $primaryColor;
              text-align: right;
            }
            .wemaBank {
              @extend %textVariationTwotwo;
              color: $dark;
              text-align: right;
              margin-left: -200px;
            }
           }
        }
        p {
          font-size: 16px;
          width: 100%;
          line-height: 16px;
          font-style: normal;
          font-weight: 400;
          margin-top: 0px;
          text-align: left;
          color: $dark-110;
          @media screen and (max-width: 480px) {
            justify-content: flex-start;
            font-size: 14px;
          }
        }
      }
    }
    .request {
      margin-top: 20px;
      padding: 0 20px;
      margin-bottom: 30px;
      font-size: 16px;
      line-height: 24px;
      font-style: normal;
      margin-left: auto;
      margin-right: auto;
      font-weight: 500;
      @extend %flex;
      @extend %inputTwo0;
    }
  }
  .form {
    max-width: 650px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    background: white;
    display: grid;
    box-shadow: none;
    position: relative;
    padding: 0;
    border-radius: 8px;
    color: $secondaryColor;
    border: none;
    z-index: -10;
    @extend %flex;
    margin-top: -1700px;
    .new_cardM {
      background: white;
      position: absolute;
      max-width: 500px;
      top: 19%;
      left: 22.5%;
      height: auto;
      // @media screen and (max-width: 675px) {
      //   max-width: 100%;
      //   top: 19%;
      //   left: 20.5%;
      // }
      // @media screen and (max-width: 480px) {
      //   max-width: 100%;
      //   top: 19%;
      //   left: 20.5%;
      // }
      // @media screen and (max-width: 414px) {
      //   max-width: 100%;
      //   top: 19%;
      //   left: 21.4%;
      // }
      h1 {
        font-size: 54px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 2px;
        color: $dark;
        @extend %flex;
        // @media screen and (max-width: 600px) {
        //   font-size: 51px;
        //   letter-spacing: 1px;
        // }
        // @media screen and (max-width: 480px) {
        //   font-size: 40px;
        //   letter-spacing: 1px;
        // }
        // @media screen and (max-width: 414px) {
        //   font-size: 32px;
        //   letter-spacing: 1px;
        // }
      }
      h3 {
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        color: $dark;
        margin-top: -50px;
        letter-spacing: 0.5px;
        @extend %flex;
        // @media screen and (max-width: 480px) {
        //   font-size: 30px;
        //   margin-top: -30px;
        // }
        // @media screen and (max-width: 960px) {
        //   font-size: 40px;
        //   margin-top: -30px;
        // }
      }
      h4 {
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        color: $primaryColor;
        margin-top: -20.5px;
        letter-spacing: 0.5px;
        @extend %flex;
        // @media screen and (max-width: 960px) {
        //   font-size: 28px;
        //   margin-top: -17px;
        // }
      }
      h5 {
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        margin-top: -19px;
        color: $primaryColor;
        letter-spacing: 0.5px;
        @extend %flex;
        // @media screen and (max-width: 960px) {
        //   font-size: 26px;
        //   margin-top: -5px;
        // }
      }
    }
    .qr_card {
      position: absolute;
      top: 58.4%;
      left: 25.9%;
      @media screen and (max-width: 675px) {
        max-width: 100%;
        top: 58.4%;
        left: 25%;
      }
      @media screen and (max-width: 600px) {
        max-width: 100%;
        top: 56.4%;
        left: 21.5%;
      }
      @media screen and (max-width: 480px) {
        max-width: 100%;
        top: 57.6%;
        left: 23.7%;
      }
      @media screen and (max-width: 414px) {
        max-width: 100%;
        top: 54.6%;
        left: 19%;
      }
    }
  }
}
