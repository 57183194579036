@import "../../assests/stylesheets/common/variables";

.board-wrap {
  position: relative;
  margin-top: -7.2rem;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    margin-top: 0rem;
  }
  h3 {
    color: $dark;
    @extend %textVariationFour;
    margin-top: -4.5rem;

    @media screen and (max-width: 1200px) {
      margin-top: 0rem;
    }
  }
  p {
    color: $grey;
    @extend %textVariationOne;
    padding-top: 10px;
  }
  ul {
    display: grid;
    margin-top: 4.5rem;
    padding: 35px 16px;
    border-radius: 12px;
    background: $backGroundSecondary;
    max-width: 334px;
    @media screen and (max-width: 1200px) {
      margin-top: 2.5rem;
    }
    @media screen and (max-width: 560px) {
      max-width: 100%;
      width: 100%;
    }
    li {
      list-style: none;
      display: flex;
      margin-bottom: 1.5rem;
      span {
        display: flex;
        margin-right: 0.7rem;
        font-size: 20px;
        .check-Icon {
          color: $green;
        }
        .circle-Icon {
          color: $dark;
        }
      }
      .text_list {
        @extend %textVariationTwoone;
        color: $dark;
      }
    }
    .onboarding-list-item {
      background-color: $white;
      padding: 13px 11px 10px 12px;
      border-radius: 8px;
    }
  }
}
