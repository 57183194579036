@import "../../assests/stylesheets/common/variables";

.business-main {
  margin-top: 30px;
  padding: 0 50px;
  @media screen and (max-width: 768px) {
    padding: 0 24px;
    margin-top: 24px;
  }
  .row {
    display: flex;
    margin: 0 -15px -15px -15px;
    flex-wrap: wrap;
    align-items: center;

    .column {
      margin-bottom: 15px;
      padding-right: 15px;
      padding-left: 15px;
      flex: 1;
      max-width: 50%;
      flex-basis: 50%;
      @media screen and (max-width: 1200px) {
        max-width: 100%;
        flex-basis: 100%;
        display: flex;
        justify-content: center;
      }

      .account-wrap {
        margin-bottom: -43rem;
        @media screen and (max-width: 768px) {
          margin-top: 2rem;
          margin-bottom: 0rem;
        }
        h3 {
          color: $dark;
          @extend %textVariationThreeone;
          margin-top: 1rem;
        }
        p {
          color: $grey;
          @extend %textVariationTwo;
          margin-top: 1rem;
          max-width: 500px;
        }
        form {
          max-width: 560px;
          height: auto;
          width: 100%;
          display: grid;
          padding: 32px 88px;
          border-radius: 8px;
          color: $secondaryColor;
          border: none;
          z-index: 1;
          box-shadow: $boxShadow2;
          background: $white;
          margin-top: 28px;
          @media screen and (max-width: 500px) {
            padding: 16px 32px;
          }

          .button {
            @extend %inputSixx;
            @extend %textVariationThree;
            margin-bottom: 8px;
            background: $backGroundSecondary;
            width: 100%;
            align-items: center;
            justify-content: center;
          }
          .button2 {
            @extend %inputSixx;
            @extend %textVariationThree;
            background: #f7f7f7;
            border-radius: 8px;
            border: none;
            @extend %inputSix;
            margin-bottom: 8px;
            width: 100%;
            background: $white;
            border: 1px solid $primaryColor;
          }
          .active,
          .button:hover,
          .button2:hover {
            background-color: $primaryColor;
            color: white;
          }
          label {
            color: $grey;
            @extend %textVariationOne;
            margin-bottom: 8px;
          }
          .alignName {
            position: relative;
            .left-inputt {
              display: grid;
              width: 10px;
              @media screen and (max-width: 1080px) {
                width: 100%;
              }
            }
            .right-inputt {
              display: grid;
              width: 10px;
              position: absolute;
              left: 200px;
              top: 0px;

              @media screen and (max-width: 1080px) {
                width: 100%;
                position: relative;
                left: 0px;
                top: 0px;
              }
            }
          }
          .business-option {
            width: 100%;
            position: relative;
            select {
              background: #f7f7f7;
              border-radius: 8px;
              padding: 0 16px;
              height: 56px;
              appearance: none;
              color: $grey;
              border: none;
              margin-bottom: 16px;
              width: 100%;
              &:focus {
                outline: none !important;
                border: 2px solid #1a71ff;
              }
              option {
                @extend %textVariationTwo;
                padding: 10px 5px;
              }
            }
            .business-down {
              position: absolute;
              display: block;
              right: 16px;
              font-size: 20px;
              top: 20px;
              pointer-events: none;
            }
          }

         .apart_state {
          display: flex;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 1.2rem;
          .biz_option {
            width: 100%;
            display: grid;
            position: relative;
            select {
              background: #f7f7f7;
              border-radius: 8px;
              padding: 0 16px;
              height: 56px;
              appearance: none;
              color: $grey;
              border: none;
              margin-bottom: 16px;
              width: 100%;
              &:focus {
                outline: none !important;
                border: 2px solid #1a71ff;
              }
              option {
                @extend %textVariationTwo;
                padding: 10px 5px;
              }
            }
            .biz_down {
              position: absolute;
              display: block;
              right: 16px;
              font-size: 20px;
              top: 45px;
              pointer-events: none;
            }
          }
         }

          input {
            @extend %inputOne;
            margin-bottom: 16px;
          }
          .businessInput {
            @extend %inputOne;
            margin-bottom: 16px;
          }
          .invalid {
            border: 1.5px solid red;
          }
          .invalid:focus {
            border: 1.5px solid red;
          }

          button {
            @extend %inputTwo;
            @extend %textVariationbtn;
            margin-top: 32px;
            margin-bottom: 32px;
            color: $white;
          }
        }
      }

      .sole-wrap {
        margin-bottom: -30.9rem;
        @media screen and (max-width: 768px) {
          margin-top: 2rem;
          margin-bottom: 0rem;
        }
        h3 {
          color: $dark;
          @extend %textVariationThreeone;
          margin-top: 1rem;
        }
        p {
          color: $grey;
          @extend %textVariationTwo;
          margin-top: 1rem;
          max-width: 500px;
        }
        form {
          max-width: 560px;
          height: auto;
          width: 100%;
          display: grid;
          padding: 32px 88px;
          border-radius: 8px;
          color: $secondaryColor;
          border: none;
          z-index: 1;
          box-shadow: $boxShadow2;
          background: $white;
          margin-top: 28px;
          @media screen and (max-width: 500px) {
            padding: 16px 32px;
          }

          .button {
            @extend %inputSixx;
            @extend %textVariationThree;
            margin-bottom: 8px;
            background: $backGroundSecondary;
            width: 100%;
            align-items: center;
            justify-content: center;
          }
          .button2 {
            @extend %inputSixx;
            @extend %textVariationThree;
            background: #f7f7f7;
            border-radius: 8px;
            border: none;
            @extend %inputSix;
            margin-bottom: 8px;
            width: 100%;
            background: $white;
            border: 1px solid $primaryColor;
          }
          .active,
          .button:hover,
          .button2:hover {
            background-color: $primaryColor;
            color: white;
          }
          label {
            color: $grey;
            @extend %textVariationOne;
            margin-bottom: 8px;
          }
          .alignName {
            position: relative;
            .left-inputt {
              display: grid;
              width: 10px;
              @media screen and (max-width: 1080px) {
                width: 100%;
              }
            }
            .right-inputt {
              display: grid;
              width: 10px;
              position: absolute;
              left: 200px;
              top: 0px;

              @media screen and (max-width: 1080px) {
                width: 100%;
                position: relative;
                left: 0px;
                top: 0px;
              }
            }
          }
          .business-option {
            width: 100%;
            position: relative;
            select {
              background: #f7f7f7;
              border-radius: 8px;
              padding: 0 16px;
              height: 56px;
              appearance: none;
              color: $grey;
              border: none;
              margin-bottom: 16px;
              width: 100%;
              &:focus {
                outline: none !important;
                border: 2px solid #1a71ff;
              }
              option {
                @extend %textVariationTwo;
                padding: 10px 5px;
              }
            }
            .business-down {
              position: absolute;
              display: block;
              right: 16px;
              font-size: 20px;
              top: 20px;
              pointer-events: none;
            }
          }
          .apart_state {
            display: flex;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 1.2rem;
            .biz_option {
              width: 100%;
              display: grid;
              position: relative;
              select {
                background: #f7f7f7;
                border-radius: 8px;
                padding: 0 16px;
                height: 56px;
                appearance: none;
                color: $grey;
                border: none;
                margin-bottom: 16px;
                width: 100%;
                &:focus {
                  outline: none !important;
                  border: 2px solid #1a71ff;
                }
                option {
                  @extend %textVariationTwo;
                  padding: 10px 5px;
                }
              }
              .biz_down {
                position: absolute;
                display: block;
                right: 16px;
                font-size: 20px;
                top: 45px;
                pointer-events: none;
              }
            }
           }

          input {
            @extend %inputOne;
            margin-bottom: 16px;
          }
          .businessInput {
            @extend %inputOne;
            margin-bottom: 16px;
          }
          .invalid {
            border: 1.5px solid red;
          }
          .invalid:focus {
            border: 1.5px solid red;
          }

          button {
            @extend %inputTwo;
            @extend %textVariationbtn;
            margin-top: 32px;
            margin-bottom: 32px;
            color: $white;
          }
        }
      }
    }
  }
}
