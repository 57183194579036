@import "../../assests/stylesheets/common/variables";

.trans-main {
  margin-top: 30px;
  padding: 0 50px;
  @media screen and (max-width: 768px) {
    padding: 0 24px;
    margin-top: 24px;
  }
  .home3-bar {
    @extend %flex;
    justify-content: space-between;
    padding: 5px 0px;

    @media screen and (max-width: 768px) {
      padding: 0 2px;
    }

    h3 {
      @extend %textVariationFour;
      @extend %flex;

      .home3-sub {
        @extend %flex;
        background: $primaryColor;
        @extend %textVariationOne;
        color: $white;
        border-radius: 4px;
        padding: 0 15px;
        width: 32px;
        height: 32px;
        margin-left: 0.5rem;
        @media screen and (max-width: 500px) {
          padding: 0 8px;
          width: auto;
          height: 25px;
        }
      }
    }
    .topbar {
      @extend %flex;
      .top-notify {
        display: flex;
        @media screen and (max-width: 500px) {
          margin-bottom: 8px;
        }
        .mini-card {
          @extend %flex;
          background: $white;
          border: 1px solid #d9d9d9;
          border-radius: 8px;
          width: auto;
          height: 48px;
          margin-right: 16px;
          cursor: pointer;
          @media screen and (max-width: 500px) {
            height: 38px;
            margin-right: 12px;
          }
          .date-icon {
            padding: 0 16px;
            @media screen and (max-width: 500px) {
              padding: 0 10px;
            }
          }
        }
        .filter-card {
          @extend %flex;
          background: $white;
          border: 1px solid #d9d9d9;
          border-radius: 8px;
          width: 198px;
          height: 48px;
          cursor: pointer;
          @media screen and (max-width: 500px) {
            height: 38px;
            width: 88px;
          }
          .filter {
            color: $grey;
            font-size: 16px;
            font-weight: 600;
            line-height: 16px;
            margin-left: 10px;
          }
        }
        .export-card {
          margin-left: 16px;
          cursor: pointer;
          @media screen and (max-width: 500px) {
            margin-right: 12px;
          }
        }
      }
    }
  }
  .transaction-page {
    .card {
      background: $primaryColor;
      background-color: #f0f6ff;
      color: #002766;
      margin-top: 40px;
      margin-bottom: 40px;
      max-width: 5500px;
      width: 100%;
      height: 168px;
      left: 48px;
      top: 120px;
      @media screen and (max-width: 2500px) {
        max-width: 2500px;
      }
      @media screen and (max-width: 1500px) {
        max-width: 1390px;
      }
      @media screen and (max-width: 1000px) {
        max-width: 620px;
      }
      @media screen and (max-width: 768px) {
        max-width: 715px;
      }
      @media screen and (max-width: 650px) {
        max-width: 600px;
      }
      @media screen and (max-width: 600px) {
        max-width: 700px;
      }
      @media screen and (max-width: 414px) {
        max-width: 365px;
      }
      .card-title {
        color: $secondaryColor;
        @extend %textVariationTwoone;
        display: inline-block;
        margin-top: 24px;
        margin-left: 22px;
      }
      h1 {
        @extend %flex;
        color: $secondaryColor;
        margin-top: 30px;
        font-weight: 700;
        font-size: 24px;
      }
      hr {
        margin-top: 16px;
        width: 97%;
        margin: 0 auto;
        border: 1px solid $white;
      }
    }
  }
  .transactionTable {
    .transaction-page {
      .card {
        background: $primaryColor;
        background-color: #f0f6ff;
        color: #002766;
        margin-top: 40px;
        max-width: 5500px;
        width: 100%;
        height: 168px;
        left: 48px;
        top: 120px;
        @media screen and (max-width: 2500px) {
          max-width: 2500px;
        }
        @media screen and (max-width: 1500px) {
          max-width: 1390px;
        }
        @media screen and (max-width: 1000px) {
          max-width: 620px;
        }
        @media screen and (max-width: 768px) {
          max-width: 715px;
        }
        @media screen and (max-width: 650px) {
          max-width: 600px;
        }
        @media screen and (max-width: 600px) {
          max-width: 700px;
        }
        @media screen and (max-width: 414px) {
          max-width: 365px;
        }
        .card-title {
          @extend %textVariationTwoone;
          display: inline-block;
          margin-top: 24px;
          margin-left: 22px;
        }
        h1 {
          @extend %flex;
          margin-top: 30px;
        }
        hr {
          margin-top: 16px;
          border: 1px solid $white;
        }
      }
    }

    .transaction-table {
      margin-top: 52px;
      border-collapse: collapse;
      margin: 25px 0;
      font-size: 16px;
      min-width: 960px;
      width: 100%;
      td {
        font-weight: 500;
        color: $dark;
      }
      .bolder__one {
        font-weight: 700;
        color: $dark;
      }
      @media screen and (max-width: 1300px) {
        min-width: 500px;
      }
      @media screen and (max-width: 1150px) {
        min-width: 500px;
      }
      @media screen and (max-width: 1000px) {
        min-width: 600px;
      }
      @media screen and (max-width: 971px) {
        min-width: 500px;
      }
      @media screen and (max-width: 920px) {
        min-width: 400px;
        display: block;
        overflow-x: auto;
      }
      @media screen and (max-width: 783px) {
        min-width: 300px;
        display: block;
        overflow-x: auto;
      }
      @media screen and (max-width: 346px) {
        min-width: 200px;
        display: block;
        overflow-x: auto;
      }
      thead tr {
        background-color: $backGroundSecondary;
        text-align: center;
        font-weight: 500;
        border: 1px solid #d7d7d7;
      }
      th,
      td {
        padding: 20px 15px;
      }
      tbody tr {
        border: 1px solid #d7d7d7;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        font-style: normal;
        text-align: center;
      }
      .view {
        color: $dark-11;

        &:hover {
          color: $primaryColor;
        }
      }
      tbody tr:nth-of-type(even) {
        background-color: $backGroundSecondary;
      }
    }
  }

  .noData {
    @extend %flex-col;
    margin-top: 162px;
    @media screen and (max-width: 480px) {
      margin-top: 80px;
    }
    h2 {
      @extend %textVariationNoData;
      margin-top: 26px;
    }
    h4 {
      @extend %textVariationTwoone;
      color: $grey;
      margin-top: -10px;
      max-width: 45%;
      text-align: center;

      @media screen and (max-width: 480px) {
        max-width: 100%;
      }
    }
  }
}
