@import "../../assests/stylesheets/common/variables";

.document-main {
  margin-top: 30px;
  padding: 0 50px;
  @media screen and (max-width: 768px) {
    padding: 0 24px;
    margin-top: 24px;
  }
  .row {
    display: flex;
    margin: 0 -15px -15px -15px;
    flex-wrap: wrap;
    align-items: center;
    .account-play {
      margin-left: 30rem;
      margin-top: -10rem;
      position: absolute;
      color: $secondaryColor;
      font-size: 20px;
      @media screen and (max-width: 1380px) {
        display: none;
      }
    }
    .column {
      margin-bottom: 15px;
      padding-right: 15px;
      padding-left: 15px;
      flex: 1;
      max-width: 50%;
      flex-basis: 50%;
      @media screen and (max-width: 1200px) {
        max-width: 100%;
        flex-basis: 100%;
        display: flex;
        justify-content: center;
      }
      .board-wrap {
        position: relative;
        margin-top: 6rem;
        margin-bottom: -10rem;
        @media screen and (max-width: 1200px) {
          flex-direction: column;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          margin-top: 0rem;
          width: 100%;
          margin-bottom: 0rem;
        }
        @media screen and (max-width: 768px) {
          margin-top: 0rem;
        }
        h3 {
          color: $dark;
          @extend %textVariationFour;
          margin-top: -4.5rem;
          @media screen and (max-width: 1200px) {
            margin-top: 0rem;
          }
        }
        p {
          color: $grey;
          @extend %textVariationOne;
          padding-top: 10px;
        }
        form {
          display: grid;
          margin-top: 4.5rem;
          padding: 32px 88px;
          border-radius: 12px;
          background: $backGroundSecondary;
          max-width: 334px;
          @media screen and (max-width: 1200px) {
            margin-top: 2.5rem;
          }
          @media screen and (max-width: 560px) {
            max-width: 100%;
            width: 100%;
            padding: 16px 32px;
          }

          li {
            list-style: none;
            display: flex;
            margin-bottom: 1.5rem;
            span {
              display: flex;
              margin-right: 0.7rem;
              font-size: 20px;
              .check-Icon {
                color: $green;
              }
              .circle-Icon {
                color: $dark;
              }
            }
          }
          .onboardingListItem {
            background-color: $white;
            padding: 13px 11px 10px 12px;
            border-radius: 8px;
          }
        }
      }
      .account-wrap {
        @media screen and (max-width: 768px) {
          margin-top: 2rem;
        }
        h3 {
          color: $dark;
          @extend %textVariationThreeone;
          margin-top: 1rem;
        }
        p {
          color: $grey;
          @extend %textVariationTwo;
          margin-top: 1rem;
          max-width: 500px;
        }
        form {
          max-width: 520px;
          height: auto;
          width: 100%;
          display: grid;
          padding: 16px 32px;
          border-radius: 8px;
          color: $secondaryColor;
          border: none;
          z-index: 1;
          box-shadow: $boxShadow2;
          background: $white;
          margin-top: 28px;

          .button5 {
            @extend %inputSixx;
            @extend %textVariationThree;
            margin-bottom: 8px;
            margin-top: 8px;
            background: $backGroundSecondary;
            border: 1px dashed #7b7b7b;
            width: 100%;
            align-items: center;
            justify-content: center;
          }
          .document-label {
            color: $grey;
            @extend %textVariationTwo;
            margin-top: 8px;
          }
          label {
            color: $grey;
            @extend %textVariationTwo;
            margin-bottom: 8px;
          }
          input {
            @extend %inputOne;
            margin-bottom: 16px;
          }
          .uploadInput {
            @extend %inputOne;
            margin-bottom: 16px;
          }

          .invalid {
            border: 1.5px solid red;
          }
          .invalid:focus {
            border: 1.5px solid red;
          }

          input[type="file"] {
            position: absolute;
            z-index: -1;
            top: 10px;
            left: 8px;
            font-size: 17px;
            color: #b8b8b8;
          }
          .btn-Wrap {
            position: relative;
          }

          .btn {
            display: inline-block;
            padding: 16px 185px;
            cursor: pointer;
            border-radius: 5px;
            width: 100%;
            font-size: 16px;
            background: $backGroundSecondary;
            border: 1px dashed #7b7b7b;
            color: $grey;
            &:hover {
              background: #f5f3f3;
            }
            @media screen and (max-width: 500px) {
              padding: 16px 105px;
            }
          }
          .sole_text {
            margin-top: 20px;
          }
          button {
            @extend %inputTwo;
            @extend %textVariationThree;
            margin-top: 1.5rem;
            margin-bottom: 24px;
          }
        }
      }
    }
  }
}
