@import "../../../../assests/stylesheets/common/variables";

.backgd--filter {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 32%;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: auto;
  background-color: 0px 0px 16px rgba(184, 184, 184, 0.25); /* Fallback color */
  background-color: 0px 0px 16px rgba(184, 184, 184, 0.25);
  /* Black w/ opacity */
  @media screen and (min-width: 1700px) {
    top: -4%;
    left: 35.2%;
  }
  @media screen and (max-width: 1400px) {
    top: 4%;
    left: 30%;
  }
  @media screen and (max-width: 1200px) {
    top: 5%;
    left: 25%;
  }
  @media screen and (max-width: 1000px) {
    top: 8%;
    left: 19%;
  }
  @media screen and (max-width: 500px) {
    top: 22%;
    left: 0%;
  }
  .filter-wrap {
    position: absolute;
    max-width: 446px;
    height: auto;
    margin: 10% auto;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
    background: #fff;
    color: #000000;
    display: grid;
    position: relative;
    z-index: 1000;
    border-radius: 16px;
    @media screen and (max-width: 480px) {
      max-width: 340px;
      width: 100%;
    }
    form {
      display: flex;
      flex-direction: column;
      color: #595959;
      .header-part {
        .add--line--bottom {
          border-bottom: 2px solid $light-grey;
          margin-bottom: 20px;
          h2 {
            color: $dark;
            @extend %textVariationTwoone;
            margin-top: 28px;
            margin-bottom: 20px;
            @extend %flex;
          }
          @media screen and (max-width: 500px) {
            margin-top: 20px;
            margin-bottom: 16px;
          }
        }
      }
      .body-part {
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 10px 45px 25px 45px;
        .list-items {
          margin-bottom: 2.5rem;
          display: flex;
          width: 100%;
          .active-class {
            background: #efefef;
            border-radius: 8px;
            height: auto;
          }
          span {
            @extend %textVariationOneone;
            color: #1a1a1a;
            margin-right: 5px;
            cursor: pointer;
            padding: 10px 16px;
            @media screen and (max-width: 500px) {
              padding: 7px 7px;
              margin-right: 0px;
            }
          }
        }
        .align-Name {
          display: flex;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 1.2rem;
          @media screen and (max-width: 768px) {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 1rem;
          }
          .left-inputt {
            display: grid;
            width: 100%;
          }
          .right-inputt {
            display: grid;
            width: 100%;
          }
        }
        .flexx {
          margin-top: 30px;
          display: grid;
        }
        .flex {
          display: grid;
        }
        input {
          @extend %inputOne;
          margin-bottom: 16px;
          color: $dark;
        }
        label {
          color: $grey;
          @extend %textVariationOne;
          margin-bottom: 8px;
        }
        .label-top {
          margin-top: 20px;
        }
        .select-option {
          width: 100%;
          position: relative;
          select {
            background: #f7f7f7;
            border-radius: 8px;
            padding: 0 16px;
            height: 56px;
            appearance: none;
            color: $grey;
            border: none;
            margin-bottom: 30px;
            margin-top: 4px;
            width: 100%;
            &:focus {
              outline: none !important;
              border: 1.5px solid #1a71ff;
            }
            option {
              @extend %textVariationTwo;
              padding: 10px 5px;
            }
          }
          .select-down {
            position: absolute;
            display: block;
            right: 16px;
            font-size: 20px;
            top: 16px;
            pointer-events: none;
          }
        }

        .log__btns {
          display: flex;
          margin-top: 40px;
          margin-left: 182px;
          @media screen and (max-width: 500px) {
            margin-top: 20px;
            margin-left: 90px;
          }
          .logout-btn {
            width: auto;
            display: flex;
            height: 48px;
            background-color: #1a71ff;
            border: none;
            justify-content: center;
            border-radius: 8px;
            padding: 16px;
            left: 1026px;
            top: 32px;
            cursor: pointer;
            @media screen and (max-width: 500px) {
              font-size: 14px;
              font-weight: 500;
              top: 12px;
              height: 38px;
              padding: 10px;
            }
          }
          .export-text {
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            color: $white;
            @media screen and (max-width: 500px) {
              font-size: 14px;
              font-weight: 500;
            }
          }
          .stay-btn {
            width: auto;
            height: 48px;
            top: 32px;
            left: 912px;
            background-color: $white;
            border: 1px solid #d9d9d9;
            border-radius: 8px;
            padding: 16px;
            margin-right: 16px;
            color: $grey;
            font-size: 16px;
            font-weight: 600;
            line-height: 16px;
            @extend %flex;
            cursor: pointer;
            @media screen and (max-width: 500px) {
              font-size: 14px;
              font-weight: 500;
              height: 38px;
              padding: 10px;
            }
          }
        }
      }
    }
  }
}
