$primaryColor: #1a71ff;
$primaryShade: #0f76d1;
$secondaryColor: #002766;
$white: #ffffff;
$dark: #000000;
$dark-100: #0d0d0d;
$dark-110: #595959;
$dark-11: #808080;
$red: #ff4f33;
$grey: #595959;
$grey-2: #8c8c8c;
$lighterGrey: #646464;
$light-grey: #f2f2f2;
$blue-50: #f0f6ff;
$green: #65bf73;
$lightGreen: #ccffe6;
$backGroundPrimary: #f4f8ff;
$lightPrimary: #f5f9ff;
$backGroundSecondary: #f7f7f7;
$backTetiary: #ccdfff;
$yellow-100: #665200;
$yellow-50: #ffda44;
$boxShadow: 0px 4px 32px rgba(223, 235, 255, 0.9);
$boxShadow2: 0px 0px 32px rgba(179, 179, 179, 0.25);
$boxShadow3: 0px 0px 16px rgba(184, 184, 184, 0.25);
$yellow: #ffc700;
$yellow1: #ffaf19;

%pointer {
  cursor: pointer;
}

%newBtn {
  width: 98px;
  height: 48px;
  top: 32px;
  left: 912px;
  background-color: $white;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 16px;
  margin-right: 16px;
  color: $grey;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  @extend %flex;
  cursor: pointer;
}

%btn-image {
  margin-right: 10px;
}

%flexself {
  align-items: center;
  justify-self: center;
  display: flex;
}

%flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

%flex-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

%flex-justify-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

%flexx {
  display: grid;
  align-items: center;
  justify-content: center;
}

%inputOne {
  background: #f7f7f7;
  border-radius: 8px;
  border: none;
  padding: 0 16px;
  height: 56px;
  &:hover {
    border: 1.5px solid #1a71ff;
  }
  &:focus {
    outline: none !important;
    border: 1.5px solid #1a71ff;
    background: #f7f7f7;
  }
}

%inputImg {
  background: #f7f7f7;
  color: #000000;
  border-radius: 8px;
  border: 1px dashed #7b7b7b;
  border: none;
  height: 56px;
  cursor: pointer;
  &:hover {
    background: #f0eded;
  }
}

%inputTwo {
  background: #1a71ff;
  color: #ffffff;
  border-radius: 8px;
  border: none;
  height: 56px;
  cursor: pointer;
  &:hover {
    background: #035ae8;
    color: #fff;
  }
}

%inputTwo0 {
  background: #1a71ff;
  color: #ffffff;
  border-radius: 8px;
  border: none;
  height: 48px;
  cursor: pointer;
  &:hover {
    background: #035ae8;
    color: #fff;
  }
}

%inputThree {
  background: #ffff;
  border-radius: 24px;
  border: none;
  padding: 13px 50px 0px 50px;
}

%inputFour {
  background: #f7f7f7;
  border-radius: 8px;
  border: none;
  padding: 19px 16px;
  height: 48px;
  width: 283px;
}

%inputFive {
  background: #f7f7f7;
  border-radius: 8px;
  border: none;
  padding: 19px 16px;
  width: 85px;
  height: 48px;
}

%inputSix {
  background: #f7f7f7;
  border-radius: 8px;
  border: none;
  padding: 19px 16px;
  height: 48px;
  width: 283px;
}

%inputSixx {
  background: #f7f7f7;
  border-radius: 8px;
  border: none;
  padding: 0px 16px;
  height: 56px;
  width: 283px;
  color: #000000;
}

%inputSeven {
  background: #f7f7f7;
  border-radius: 8px;
  border: none;
  padding: 19px 0px;
  height: 48px;
  width: 283px;
}

%inputEdit {
  background: #ffffff;
  color: #595959;
  border-radius: 8px;
  border: none;
  height: 56px;
  max-width: 150px;
  cursor: pointer;
  &:hover {
    background: #d9d9d9;
    color: #595959;
  }
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
}

%textVariationzero {
  font-size: 12px;
  line-height: 16px;
  font-style: normal;
  font-weight: 400;
}

%textVariationOne {
  font-size: 14px;
  line-height: 17px;
  font-style: normal;
  font-weight: 400;
}

%textVariationOneone {
  font-size: 14px;
  line-height: 17px;
  font-style: normal;
  font-weight: 500;
}

%textVariationTwo {
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  font-weight: 400;
  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
}

%textVariationTwoone {
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  font-weight: 500;
  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
}

%textVariationTwotwo {
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  font-weight: 600;
  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
}

%textVariationTwo2 {
  font-size: 16px;
  line-height: 16px;
  font-style: normal;
  font-weight: 500;
  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
}

%textVariationbtn {
  font-size: 16px;
  line-height: 16px;
  font-style: normal;
  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
}

%textVariationThree {
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  font-weight: 500;
  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
}

%textVariationMino {
  font-size: 16px;
  line-height: 19px;
  font-style: normal;
  font-weight: 500;
  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
}

%textVariationThreeone {
  font-size: 18px;
  line-height: 24px;
  font-style: normal;
  font-weight: 600;
  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
}

%textVariationFour {
  font-size: 22px;
  line-height: 24px;
  font-style: normal;
  font-weight: 600;
  @media screen and (max-width: 500px) {
    font-size: 18px;
    line-height: 24px;
    font-style: normal;
    font-weight: 600;
  }
}

%textVariationFive {
  font-size: 24px;
  line-height: 32px;
  font-style: normal;
  font-weight: 600;
  @media screen and (max-width: 500px) {
    font-size: 18px;
    line-height: 24px;
  }
}

%textVariationSix {
  font-size: 22px;
  line-height: 24px;
  font-style: normal;
  font-weight: 600;
  @media screen and (max-width: 500px) {
    font-size: 18px;
    line-height: 24px;
  }
}

%textVariationSeven {
  font-size: 16px;
  line-height: 16px;
  font-style: normal;
  font-weight: 500;
  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
}

%textVariationSevenone {
  font-size: 16px;
  line-height: 16px;
  font-style: normal;
  font-weight: 400;
  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
}

.redBtn {
  display: inline-flexbox;
  height: 48px;
  padding: 0;
  background: #ff4f33;
  border: none;
  border-radius: 8px;
  overflow: hidden;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  @media screen and (max-width: 500px) {
    font-size: 14px;
    height: 38px;
    font-weight: 500;
  }
  .red--txt {
    display: inline-flex;
    align-items: center;
    padding: 16px 20px 16px 20px;
    color: #ffffff;
    height: 100%;
    @media screen and (max-width: 500px) {
      padding: 9px 12px 7px 12px;
    }
  }
}

.blueBtn {
  display: inline-flexbox;
  height: 48px;
  padding: 0;
  border: none;
  border-radius: 8px;
  overflow: hidden;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  background: #1a71ff;
  color: #ffffff;
  @media screen and (max-width: 500px) {
    font-size: 14px;
    height: 38px;
    font-weight: 500;
  }
  .blue--txt {
    display: inline-flex;
    align-items: center;
    padding: 17px 20px 16px 20px;
    color: #ffffff;
    height: 100%;
    @media screen and (max-width: 500px) {
      padding: 9px 12px 7px 12px;
    }
  }
}
.whiteBtn {
  display: inline-flexbox;
  height: 48px;
  padding: 0;
  border: none;
  border-radius: 8px;
  overflow: hidden;
  font-size: 16px;
  font-weight: 600;
  background: #ffffff;
  color: #595959;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  @media screen and (max-width: 500px) {
    font-size: 14px;
    height: 38px;
    font-weight: 500;
  }
  .white--txt {
    display: inline-flex;
    align-items: center;
    padding: 18px 20px 16px 20px;
    color: #595959;
    height: 100%;
    @media screen and (max-width: 500px) {
      padding: 9px 12px 7px 12px;
    }
  }
}

%cardTextVariation {
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  font-weight: 400;
  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
}

%textVariationNoData {
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  color: #0d0d0d;
  font-style: normal;
  @media screen and (max-width: 500px) {
    font-size: 24px;
  }
}

%cardDescription {
  display: flex;
  justify-content: space-between;
  margin-left: 32px;
  @media screen and (max-width: 500px) {
    margin-left: 20px;
  }

  p {
    @extend %cardTextVariation;
    color: $dark;
  }
  .secnd {
    margin-right: 32px;
    @media screen and (max-width: 500px) {
      margin-right: 20px;
    }
  }
}

%cardDescription2 {
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
  margin-left: 32px;
  @media screen and (max-width: 500px) {
    margin-right: 20px;
  }

  p {
    @extend %cardTextVariation;
    color: $dark;
  }
  .second {
    margin-right: 32px;
    @media screen and (max-width: 500px) {
      margin-right: 20px;
    }
  }
}
#disabled--input {
  background: #f7f7f7;
  border-radius: 8px;
  border: none;
  padding: 0 16px;
  height: 56px;
  margin-bottom: 16px;
}
.timer {
  padding: 0 0 0 5px;
}

%QRdesign {
  width: 80px;
  height: 32px;
  left: 92.88px;
  top: 20px;
  background-color: #ccffe6;
  color: #000000;
  border-radius: 4px;
  padding: 8px 12px;
}

header {
  margin-left: 284px;
  background: $white;
  // @media screen and (max-width: 1000px) {
  //   margin-left: 100px;
  //   padding: 10px 0;
  // }
  @media screen and (max-width: 768px) {
    margin-left: 0px;
    padding: 10px 0;
  }
}

.link {
  color: #ffffff;
}

.link2 {
  color: #1a71ff;
}

.link3 {
  color: #002766;
}

.link4 {
  color: #000000;
  &:hover {
    color: #000000;
  }
}

.invalid {
  border: 1.5px solid red;
}
.invalid:focus {
  border: 1.5px solid red;
}
.error__txt {
  font-size: 14px;
  margin-top: -10px;
  color: red;
  font-weight: 400;
}

.error__txtbtm {
  font-size: 14px;
  margin-top: -10px;
  margin-bottom: 16px;
  color: red;
  font-weight: 400;
}

.error__sub {
  font-size: 14px;
  margin-top: -10px;
  color: red;
  font-weight: 400;
}

.create__label {
  margin-bottom: 20px;
}

.error__tt {
  font-size: 14px;
  margin-top: -16px;

  color: red;
  font-weight: 400;
}

.error__text {
  font-size: 14px;
  margin-top: -25px;
  margin-bottom: 16px;
  color: red;
  font-weight: 400;
}

.error__mssg {
  font-size: 14px;
  padding-bottom: 6px;
  padding-top: 8px;
  color: red;
  font-weight: 400;
}

.businessInput {
  @extend %inputOne;
  margin-bottom: 16px;
}

.invalid-Amount {
  border-bottom: 2px solid red;
  border-top: 2px solid red;
  border-right: 2px solid red;
  border-radius: 0 8px 8px 0;
  border-left: none;
}
.invalid-Amount:focus {
  border-bottom: 2px solid red;
  border-top: 2px solid red;
  border-right: 2px solid red;
  border-left: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.tooltip {
  border-radius: 0.35rem;
  background: #fff;
  color: #504e4e;
  padding: 1rem;
  padding-bottom: 0.25rem;
  box-shadow: 15px 15px 15px 5px rgba(0, 0, 0, 0.2);
  text-align: center;
  .reduceMagin {
    margin-top: -0.5rem !important;
    color: #a7a4a4;
  }
}

.pagination--D {
  @extend %flex;
  justify-content: space-between;
  padding: 48px 28px 16px 28px;
  border: 1px solid #d7d7d7;
  margin-top: -25px;
  margin-bottom: 36px;
  @media screen and (max-width: 480px) {
    padding: 10px;
    margin-bottom: 30px;
  }
  .items-to-show {
    @extend %flex;
    width: 240px;
    position: relative;
    p {
      color: #595959;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      width: 100%;
      height: 24px;
      line-height: 24px;
      @media screen and (max-width: 500px) {
        display: none;
      }
    }
    select {
      background: $light-grey;
      border-radius: 8px;
      padding: 0 18px;
      height: 40px;
      appearance: none;
      color: $dark;
      border: none;
      margin-bottom: 16px;
      width: 130px;
      height: 48px;
      @media screen and (max-width: 500px) {
        height: 35px;
        width: 50px;
        padding: 0 8px;
        margin-left: -34px;
      }
      option {
        @extend %textVariationTwo;
        padding: 10px 5px;
      }
    }
    .business-down {
      position: absolute;
      display: block;
      right: 16px;
      top: 15px;
      color: $dark;
      font-size: 20px;
      pointer-events: none;
      @media screen and (max-width: 500px) {
        right: 117px;
        top: 10px;
        font-size: 18px;
      }
      @media screen and (max-width: 395px) {
        right: 105px;
        top: 10px;
        font-size: 18px;
      }
    }
  }

  .edit-fees {
    color: #595959;
    background-color: #ffffff;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    margin-right: 16px;
    padding: 8px 12px;
    @media screen and (max-width: 768px) {
      padding: 4px 6px;
    }
  }

  .delete-fees {
    color: #fff;
    background-color: red;
    border-radius: 8px;
    padding: 8px 12px;
    @media screen and (max-width: 768px) {
      padding: 4px 6px;
    }
  }

  .pagination__container {
    background: $white;
    list-style: none;
    display: flex;
    align-items: center;
  }
  .pagination__container a {
    width: 40px;
    height: 40px;
    background-color: $white;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    margin-right: 10px;
    color: $grey;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    @extend %flex;
    cursor: pointer;
    &:hover {
      background: #f7f7f7;
      color: $grey;
      border: none;
    }
    @media screen and (max-width: 500px) {
      font-size: 12px;
      width: 24px;
      height: 24px;
      margin-right: 4px;
    }
  }
  .pages-p {
    width: 40px;
    height: 40px;
    background-color: $white;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    margin-right: 10px;
    color: $grey;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    @extend %flex;
    cursor: pointer;
    @media screen and (max-width: 500px) {
      font-size: 12px;
      width: 24px;
      height: 24px;
      margin-right: 4px;
    }
  }

  .active a {
    width: 40px;
    height: 40px;
    background-color: $primaryColor;
    border-radius: 4px;
    margin-right: 10px;
    color: $white;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    @extend %flex;
    cursor: pointer;
    @media screen and (max-width: 500px) {
      font-size: 12px;
      width: 24px;
      height: 24px;
      margin-right: 4px;
    }
  }
}

#export-btn {
  @extend %flex;
  border: none;
  background: $primaryColor;
  border-radius: 8px;
  width: 84px;
  height: 48px;
  color: $white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  @media screen and (max-width: 500px) {
    height: 38px;
    width: 88px;
  }
  &:hover {
    background: $primaryShade;
    color: $white;
  }
}

.small__card {
  @extend %flex;
  background: $primaryColor;
  @extend %textVariationOne;
  color: $white;
  border-radius: 4px;
  padding: 8px;
  margin-left: 0.5rem;
  @media screen and (max-width: 500px) {
    padding: 5px;
  }
}

.btn_white_card {
  background: $white;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  @extend %flex;
  color: $grey;
  border-radius: 8px;
  padding: 12px;
  @media screen and (max-width: 500px) {
    padding: 8px;
  }
}

.whiteCard_text {
  color: $grey;
  @extend %textVariationTwo2;
  margin-left: 10px;
}

.btn_blue_card {
  cursor: pointer;
  @extend %flex;
  background: $primaryColor;
  border-radius: 8px;
  color: $white;
  padding: 12px;
  @media screen and (max-width: 500px) {
    padding: 8px;
  }
}

.blueCard_text {
  color: $white;
  @extend %textVariationTwo2;
  margin-left: 8px;
}

.card__space {
  margin-left: 16px;
  @media screen and (max-width: 500px) {
    margin-right: 12px;
  }
}

.icon__mg {
  font-size: 22px;
  font-weight: 600;
  @media screen and (max-width: 480px) {
    font-size: 17px;
  }
}


.dynamic-transfer {
  padding: 8px 10px;
 background: #DBEAFF;
 color: #000000;
 border-radius: 4px;
 @media screen and (max-width: 768px) {
   padding: 4px 23px;
 }
}

