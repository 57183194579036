.container {
  align-items: center;
  justify-self: center;
  display: flex;
  img {
      padding-right: 10px;
      justify-content: flex-start;
    //   border-radius: 50px;
  }
  .message--content {
      display: grid;
      b {
          font-weight: 500;
          font-size: 15px;
      }
  }
}
