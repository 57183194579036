.notfound__page {
  margin-top: 3rem;
  display: grid;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 22px;
    line-height: 24px;
    font-style: normal;
    font-weight: 500;
    display: grid;
    align-items: center;
    justify-content: center;
  }
  img {
    margin-top: 1rem;
    display: grid;
    margin-left: 5rem;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 960px) {
      margin-left: 1.5rem;
    }
  }
  .notfound-center {
    font-size: 16px;
    line-height: 24px;
    font-style: normal;
    font-weight: normal;
    display: grid;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }
}
